<template>
    <header-component/>
    <!-- Breadcrumb Section Start -->
  <breadscrumb-section pageName="Order Tracking" />
  <!-- Breadcrumb Section End -->

    <!-- Order Detail Section Start -->
    <section class="order-detail mt-5">
        <div class="container-fluid-lg">
            <div class="row g-sm-4 g-3">
                <div class="col-xxl-12 col-xl-12 col-lg-12">
                    <div class="row g-sm-4 g-3">                        
                        <div class="col-12 overflow-hidden">
                            <ol class="progtrckr">
                                <li :class="ordered ? 'progtrckr-done':'progtrckr-todo'">
                                    <h5>Order</h5>
                                    <h6>{{ ordered? ordered:'Processing' }}</h6>
                                </li>
                                <li :class="processing ? 'progtrckr-done':'progtrckr-todo'">
                                    <h5>Processing</h5>
                                    <h6>{{ processing? processing:'Processing' }}</h6>
                                </li>
                                <li :class="shipped ? 'progtrckr-done':'progtrckr-todo'">
                                    <h5>Shipped</h5>
                                    <h6>{{ shipped? shipped:'Processing' }}</h6>
                                </li>
                                <li :class="deliverd ? 'progtrckr-done':'progtrckr-todo'">
                                    <h5>Delivered</h5>
                                    <h6>{{ deliverd? deliverd:'Processing' }}</h6>
                                </li>
                                <!-- <template v-for="(orderTracking, index) in orderTrackingData" :key="index">   
                                    <li v-if="orderTracking.status=='ORDERED'" class="progtrckr-done">
                                        <h5>{{ orderTracking.status }}</h5>
                                        <h6>{{ $filters.longDateCreatedAt(orderTracking.created_at) }}</h6>
                                    </li>
                                    {{ $filters.longDateCreatedAt(ordered) }}
                                </template>
                                 -->
                                <!-- <li class="progtrckr-todo">
                                    <h5>Delivered</h5>
                                    <h6>Pending</h6>
                                </li> -->
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Order Detail Section End -->
</template>
<script>
import { mapActions } from "vuex";
import BreadscrumbSection from '@/components/forntEnd/BreadscrumbSection.vue';
import HeaderComponent from '@/components/template/front/HeaderComponent.vue';
import { toast } from "vue3-toastify";
export default {
  data() {
    return {
        orderTrackingData: [],
        ordered: null,
        processing: null,
        shipped: null,
        deliverd: null,
    };
  },
  created() { 
    if(!this.$route.params.orderDisplayId){
      return this.$router.push({name: 'OrderList'})
    }        
    this.orderTracking();

  },
  methods: {
    async orderTracking(){ 
      let vueInstance = this;          
      await vueInstance.actionOrderTracking({orderDisplayId: vueInstance.$route.params.orderDisplayId}).then(results => {
        if (results.error == true) {
            toast.error(results.message);
            return false
        }
        vueInstance.orderTrackingData = results.response.activities

        let orderedActivity = vueInstance.orderTrackingData.filter(function (i) {
            return i.status == "ORDERED";
        }).map(function (i) {
            return i.created_at;
        })
        vueInstance.ordered = vueInstance.longDateCreatedAt(orderedActivity)

        let processingActivity = vueInstance.orderTrackingData.filter(function (i) {
            return i.status === "PROCESSING";
        }).map(function (i) {
            return i.created_at;
        })
        vueInstance.processing = vueInstance.longDateCreatedAt(processingActivity)

        let shippedActivity = vueInstance.orderTrackingData.filter(function (i) {
            return i.status == "SHIPPED";
        }).map(function (i) {
            return i.created_at;
        })
        vueInstance.shipped = vueInstance.longDateCreatedAt(shippedActivity)

        let deliverdActivity = vueInstance.orderTrackingData.filter(function (i) {
            return i.status == "DELIVERED";
        }).map(function (i) {
            return i.created_at;
        })
        vueInstance.deliverd = vueInstance.longDateCreatedAt(deliverdActivity)
        return false;
      }).catch((err) => {
        toast.error(err.message);
        return false;
      });
    },
    longDateCreatedAt(dateFormate) {
        if  (dateFormate.length <= 0)  { 
            return  false;
        }
        var today = new Date ();
        var date = new Date (dateFormate);
        if (today.toDateString () == date.toDateString ()) {
            return date.toLocaleString ([], {hour: '2-digit', minute: '2-digit', hour12: true})
        } 
        // return  date.toLocaleString ('en-GB', {year: 'numeric', month: '2-digit', day: '2-digit',  hour12: true});
        return  date.toLocaleString ('en-GB', { hour12: true });
    },
    ...mapActions("customer", {
        actionOrderTracking: "orderTracking",
    }),
  },
  components: {  BreadscrumbSection, HeaderComponent },
};
</script>