import axios from 'axios'
import axiosInstance from '../../helper/axiosInstance'
import router from '../../router';
import { Role } from '../../helper/role'
import { toast } from 'vue3-toastify';

const state = () => ({
    authData: {
        token: "",
        fullName:"",
        loggedInRole:"",
    },
});
const mutations = {
    setAuthData(state, data) {
        const token = data.token;
        const loggedInRole = data.role;
        localStorage.setItem("token", token);
        const newAuthData = {
            token: token,
            loggedInRole:loggedInRole,
        };
        state.authData = newAuthData;
    },
    BEFORE_VERIFIED(state, data) {
        localStorage.setItem("userData", JSON.stringify(data));
    },
};
const getters = {
    getAuthData(state) {
        return state.authData
    },
};
const actions = {
    async checkLogin({ commit }, payload) { 
        return axiosInstance.post('auth/login', payload).then(req => {
            // if (req.data.error == false) {
            //     commit('BEFORE_VERIFIED', payload)
            //     localStorage.setItem("userMobile", req.data.response.user.phone);
            // }
            return req.data;
        }).catch(err => {
            throw err;
        })
    },

    async otpVerify({ commit }, payload) {
        return axiosInstance.post('otp/verify', payload).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },

    async loginVerify({ commit }, payload) {
        return axiosInstance.post('auth/login/verify', payload).then(req => {
            console.log(req);
            if (req.data.error == false) {
                const data = {
                    token: req.data.response.token_info.access_token,
                    role: req.data.response.user.account.role,
                }
                commit('setAuthData', data)
            }
            return req.data;
        }).catch(err => {
            throw err;
        })
    },

    async login({ commit }, payload) {
        return axiosInstance.post('auth/login', payload).then(req => {
            // if (req.data.error == false) {
            //     const data = {
            //         token: req.data.response.token_info.access_token,
            //         role: req.data.response.user.account.role,
            //     }
            //     commit('setAuthData', data)
            // }
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    
    async resetPasswordLink({ commit }, payload) {
        return axiosInstance.post('admin/auth/reset-password-link', payload).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },














    
    async updatePssword({ commit }, payload) {
        return axiosInstance.post('admin/auth/reset-password', payload).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async logout({ commit }) {
        delete axios.defaults.headers.common['Authorization'];
        const newAuthData = {
            token: "",
            role: "",
        };
        commit('setAuthData', newAuthData)
        localStorage.removeItem("token");
        router.push({ name: 'Login' })
    },
    async WhenError({ commit }) {
        delete axios.defaults.headers.common['Authorization'];
        const data = {
            token: "",
            role: "",
            fullName:"",
          };
        commit('setAuthData', data)
        localStorage.removeItem("token"); 
        // toast.error("Something went wrong. Please try to login in again.");
        // router.push({name:'Login'})
        return false
    },

    async sinUpCheck({ commit }, payload) {
        return axiosInstance.post('auth/customer/registration/check', payload).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },

    async registration({ commit }, payload) {
        return axiosInstance.post('auth/customer/registration/final', payload).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    
    async checkExistUser({ commit }, payload) {
        const payloadData = {
            mobile: payload.mobile,
        };
        return axiosInstance.post('auth/forgot/check', payloadData).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    
    async sendOTP({ commit }, payload) { 
        return axiosInstance.get(`otp?phone=${payload.phoneNo}`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    
    async profile() { 
        return axiosInstance.get('auth/profile').then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async updateProfile({ commit }, payload) { 
        const payloadData = {
            mobile: payload.mobileNo,
            password: payload.password,
            email: payload.email,
        };
        return axiosInstance.post('auth/profile', payloadData).then(req => {
            if (req.data.error == false) { 
                const data = {
                    token: req.data.response.token_info.access_token,
                    role: req.data.response.user.account.role,
                    fullName:req.data.response.user.first_name +' '+req.data.response.user.last_name,
                }
                commit('setAuthData', data)
            }
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    
    async adminLogin({ commit }, payload) {
        const payloadData = {
            user_name: payload.userName,
            password: payload.password,
        };
        return axiosInstance.post('auth/login', payloadData).then(req => {
            if (req.data.error == false) {
                const data = {
                    token: req.data.response.token_info.access_token,
                    role: req.data.response.user.account.role,
                    fullName:req.data.response.user.first_name +' '+req.data.response.user.last_name,
                }
                commit('setAuthData', data)
            }
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    

    async admimInfoUpdate({ commit }, payload) { 
        const payloadData = {
            first_name: payload.firstName,
            last_name: payload.lastName,
        };
        return axiosInstance.put('admin/auth/profile', payloadData).then(req => {
            if (req.data.error == false) { 
                const data = {
                    token: req.data.response.token_info.access_token,
                    role: req.data.response.user.account.role,
                    fullName:req.data.response.user.first_name +' '+req.data.response.user.last_name,
                }
                commit('setAuthData', data)
            }
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}