import { createApp } from 'vue';
import Vuex from 'vuex';
import authModule from './modules/auth';
import customerModule from './modules/customer';

createApp().use(Vuex);

const store = new Vuex.Store({
  modules:{
    auth:authModule,
    customer:customerModule,
  }
});

export default store;