import axiosInstance from '../../helper/axiosInstance'
const state = () => ({
    totalCartCount:0,
    userProfileData:[],
    addedProducts:{},
});
const mutations = {
    incrementCart (state, quantity) {
        state.totalCartCount =quantity
    },
    userProfile (state, data) {
        state.userProfileData =data
    },
    addedProducts (state, data) {
        state.addedProducts =data
    }
};
const getters = {

};
const actions = {

    async getCategories({ commit }, payload) {

        return axiosInstance.get(`categories`, payload).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },

    async getTopLines({ commit }, payload) {

        return axiosInstance.get(`top_lines`, payload).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },

    async getHomeDataList({ commit }, payload) {

        return axiosInstance.get(`dashboard`, payload).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },

    async getCategoryProductsList({ commit }, payload) {

        return axiosInstance.get(`categories/products`, payload).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async addToProduct({ commit }, payload) {

        return axiosInstance.post(`auth/vendor/products`, payload).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },

    async contactUs({ commit }, payload) {

        return axiosInstance.post(`contact-us`, payload).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async customerProfile({ commit }, payload) {
        return axiosInstance.get('auth/customer/profile').then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async customerProfile({ commit }, payload) {
        return axiosInstance.get('auth/customer/profile').then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async orderTracking({ commit }, payload) {
        return axiosInstance.get(`customer/orders/${payload.orderDisplayId}/tracking`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async returnOrder({ commit }, payload) {
        return axiosInstance.post(`customer/orders/${payload.orderDisplayId}/return`,payload).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async customerProfileUpdate({ commit }, payload) {
        return axiosInstance.put(`auth/customer/profile`, payload).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async addresses({ commit }, payload) {

        return axiosInstance.get(`customer/addresses`, payload).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },

    async getFiltersDataList({ commit }, payload) {

        return axiosInstance.get(`products-filter`, payload).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async deliveryAddressUpdate({ commit }, payload) {
        
        if(payload.addressId && payload.addressId !=''){
            return axiosInstance.put(`customer/addresses/${payload.addressId}`, payload).then(req => {
                return req.data;
            }).catch(err => {
                throw err;
            })
        }else{
            return axiosInstance.post(`customer/addresses`, payload).then(req => {
                return req.data;
            }).catch(err => {
                throw err;
            })
        }
    }, 

    async addToProductCart({ dispatch }, payload) {

        return axiosInstance.post(`customer/carts`, payload).then(req => {
            if(req.data.error == false){
                dispatch('productCartCount');
            }
            return req.data;
        }).catch(err => {
            throw err;
        })
    },

    async updateToProductQyt({ dispatch }, payload) {

        return axiosInstance.put(`customer/carts`, payload).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },

    async settings({ commit }, payload) {
        return axiosInstance.get(`customer/global-settings`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },

    async wishlistProducts({ commit }, payload) {
        return axiosInstance.get(`customer/wish_lists`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },

    async getCartListData({ commit }, payload) {

        return axiosInstance.get(`customer/carts`, payload).then(req => {
            // if(req.data.error == false){
                commit('addedProducts', req.data.response)
            // }
            return req.data;
        }).catch(err => {
            throw err;
        })
    },

    async deleteCartProduct({ dispatch }, payload) {
        return axiosInstance.delete(`customer/carts/${payload.id}`).then(req => {
            if(req.data.error == false){
                dispatch('productCartCount');
                dispatch('getCartListData');
            }
            return req.data;
        }).catch(err => {
            throw err;
        })
    },

    async deleteAddress({ dispatch }, payload) {
        return axiosInstance.delete(`customer/addresses/${payload.id}`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },

    async deleteAcount({ dispatch }, payload) {
        return axiosInstance.delete(`auth/customer/profile`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },

    async submitCheckOut({ dispatch }, payload) {
        
        return axiosInstance.post(`customer/orders/checkout`, payload).then(req => {
            dispatch('productCartCount');
            return req.data;
        }).catch(err => {
            throw err;
        })
    },

    async checkCouponCode({ commit }, payload) {

        return axiosInstance.post(`customer/coupons/apply`, payload).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },

    async getReferCode({ commit }, payload) {

        return axiosInstance.get(`customer/referral-code`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },

    async addToWish({ commit }, payload) {

        return axiosInstance.post(`customer/wish_lists`, payload).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },

    async addToWishRemoveLater({ dispatch }, payload) { 

        return axiosInstance.post(`customer/wish_lists`, payload).then(req => {
            dispatch('productCartCount');
            return req.data;
        }).catch(err => {
            throw err;
        })
    },

    async productCartCount({ commit, dispatch }, payload) {

        return axiosInstance.get(`customer/carts/count`, payload).then(req => {
            if(req.data.error == false){
                commit('incrementCart', req.data.response.cart_count)
                dispatch('getCartListData');
            }
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async productDetails({ commit }, payload) {
        return axiosInstance.get(`products/${payload.id}`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },

    async checkoutTest({ commit }, payload) {
        return axiosInstance.get(`customer/test-payment`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async getOrders({ commit }, payload) {
        return axiosInstance.get(`customer/orders?page=${payload.page}`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },
    async getOrderProducts({ commit }, payload) {
        return axiosInstance.get(`customer/orders/${payload.orderDisplayId}`).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },

    async uploadImage({ dispatch }, payload) {
        return axiosInstance.post(`file/upload`, payload).then(req => {
            if(req.data.error == false){
                var photoUrl ={
                    account:{
                        photo: req.data.response.file_name
                    } 
                }
                dispatch('customerProfileUpdate', photoUrl)
            }
            return req.data;
        }).catch(err => {
            throw err;
        })
    },

    async addReview({ commit }, payload) {
        console.log(payload);
        return axiosInstance.post(`customer/orders/${payload.order_id}/feedback`, payload).then(req => {
            return req.data;
        }).catch(err => {
            throw err;
        })
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
