<template>
  
  <header class="">
    <div class="top-nav top-header sticky-header">
      <div class="header-top">
        <div class="container-fluid-lg">
          <div class="row">
            <div class="col-xxl-12 col-lg-12 d-lg-block">
              <div class="header-offer">
                  <swiper v-if="topLines.length > 0" class="inotification-slider"
                    :slidesPerView="1"
                    :centeredSlides="true"
                    :autoplay="{
                        delay: 1000,
                    }"
                    :modules="modules"
                    :effect="'fade'"
                    :lazy="true"
                    :direction="'vertical'"
                    :speed="1000"
                    :loop="true"                   
                  >                  
                    <swiper-slide v-for="(topLine,jIndex) in topLines" :key="jIndex" class="timer-notification">
                      <h6 v-html="topLine"></h6>
                    </swiper-slide>
                </swiper>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid-lg">
        <div class="row">
          <div class="col-12">
            <div class="navbar-top">
              <button class="navbar-toggler d-xl-none d-inline navbar-menu-button me-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#primaryMenu">
                <span class="navbar-toggler-icon">
                  <i class="fa-solid fa-bars"></i>
                </span>
              </button>
              <!-- <router-link :to="{ name: 'Home' }" class="web-logo nav-logo"> 
                <img src="../../../assets/images/logo/KOG.png" class="img-fluid blur-up lazyload" alt="" />
              </router-link> -->
              <a href="/" class="web-logo nav-logo"> 
                <img src="../../../assets/images/logo/KOG.png" class="img-fluid blur-up lazyload" alt="" />
              </a>

              <div class="header-nav-middle">
                <div class="main-nav navbar navbar-expand-xl navbar-light navbar-sticky">
                  <div class="offcanvas offcanvas-collapse order-xl-2" id="primaryMenu">
                    <div class="offcanvas-header navbar-shadow">
                      <h5>Menu</h5>
                      <button class="btn-close lead" type="button" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div class="offcanvas-body">
                      <ul class="navbar-nav">
                        <li v-for="(categoryData,index) in categoryListData" :key="index" data-bs-dismiss="offcanvas" aria-label="Close">
                          <router-link :to="{ name: 'ShopByCategory',params:{categorySlug: categoryData.slug}}" class="dropdown-item">
                            <b>  {{categoryData.name}}   </b>
                          </router-link>
                          <!-- <a :href="`#/shop-by-category/${categoryData.slug}`" class="dropdown-item">
                              {{categoryData.name}}   
                          </a> -->
                        </li>
                        
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="rightside-box">
                <!-- <div :class="[openSearch ? 'search-full open' : 'search-full',]">
                  <div class="input-group">
                    <span class="input-group-text">
                      <i data-feather="search" class="font-light"></i>
                    </span>
                    <input type="text" v-model="serachProduct" class="form-control search-type" placeholder="Search here.." />
                    <span class="input-group-text close-search" @click="closeSearchBox">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x font-light"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                    </span>
                  </div>
                </div> -->
                
                <ul class="right-side-menu">
                  <!-- <li>
                    <a href="javascript:void(0)" class="header-icon search-box search-icon"> </a>
                  </li> -->
                  <li class="right-side kog-search">
                    <div class="delivery-login-box">
                      <div class="delivery-icon">
                        <div class="search-box" @click="openSearchBox">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="right-side kog-wishlist">
                    <router-link :to="{ name: 'WishList' }"  class="btn p-0 position-relative header-wishlist">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-heart"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg>
                    </router-link>
                  </li>
                  <li class="right-side kog-cart">
                    <div class="onhover-dropdown header-badge" >
                      <button type="button" class="btn p-0 position-relative header-wishlist">
                        <!-- <i data-feather="shopping-cart"></i> -->
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-cart"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>
                        <span class="position-absolute top-0 start-100 translate-middle badge"
                          >{{totalCartCount}}</span>
                      </button>

                      <div class="onhover-div" v-if="$store.state.customer.addedProducts && totalCartCount > 0">
                        <ul class="cart-list">
                          <li v-for="(cartData) in $store.state.customer.addedProducts.items" :key="cartData.id" class="product-box-contain">
                            <div class="drop-cart">
                              <router-link :to="{ name: 'ProductDetails',params:{productSlug: cartData.products.slug}}" class="drop-image">
                                <img :src="cartData.products.images[0].image_url" class="blur-up lazyload" alt="" />
                              </router-link>

                              <div class="drop-contain"> 
                                <router-link :to="{ name: 'ProductDetails',params:{productSlug: cartData.products.slug}}">
                                  <h5>{{ cartData.products.name }}</h5>
                                </router-link>
                                <h6><span>{{ cartData.quantity }} x</span> ₹{{ cartData.products.variant.sale_price }}</h6>
                                <!-- <button @click.prevent="deleteProduct(cartData.id)" class="close-button close_button">
                                  <i class="fa-solid fa-xmark"></i>
                                </button> -->
                              </div>
                            </div>
                          </li>
                        </ul>

                        <div class="price-box">
                          <h5>Total :</h5>
                          <h4 class="theme-color fw-bold">₹{{ $store.state.customer.addedProducts.cartSubTotal }}</h4>
                        </div>

                        <div class="button-group">
                          <router-link :to="{ name: 'AddToCart' }" class="btn btn-sm cart-button">View Cart</router-link>
                          <router-link :to="{ name: 'CheckOutPorcess' }" class="btn btn-sm cart-button theme-bg-color text-white">Checkout</router-link>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="right-side onhover-dropdown">
                    <div class="delivery-login-box">
                      <div class="delivery-icon">
                        <!-- <i data-feather="user"></i> -->
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                      </div>
                      <!-- <div class="delivery-detail">
                        <h6>Hello,</h6>
                        <h5>My Account</h5>
                      </div> -->
                    </div>

                    <div class="onhover-div onhover-div-login">
                      <ul class="user-box-name" v-if="!checkTokenLoggedIn">
                        <li class="product-box-contain">
                          <i></i>
                          <router-link :to="{ name: 'Login' }">Log In</router-link>
                        </li>
                        <!-- <li class="product-box-contain">
                          <router-link :to="{ name: 'SignUp' }">Register</router-link>
                        </li> -->
                      </ul>
                      <ul class="user-box-name" v-else> 
                        <li class="product-box-contain">
                          <router-link :to="{ name: 'OrderList' }">My Orders</router-link>
                        </li>
                        <li class="product-box-contain header-dashboard">
                          <router-link :to="{ name: 'MyAddress' }">Address</router-link>
                        </li>
                        <li class="product-box-contain header-dashboard">
                          <router-link :to="{ name: 'MyProfile' }">Profile</router-link>
                        </li>
                        <li class="product-box-contain">
                          <router-link :to="{ name: 'ReferCode' }">Refer & Earn</router-link>
                        </li>
                        <li class="product-box-contain courser" @click.prevent="logout">
                          Log Out
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <!-- Header End -->
</template>

<script>
// import image from "../../../assets/images/logo/4.png"
import "../../../assets/css/animate.min.css";
import "../../../assets/css/vendors/ion.rangeSlider.min.css";
import "../../../assets/css/font-style.css";
// import '../../../assets/css/style.css';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay} from 'swiper';
import 'swiper/css'
import { mapActions } from "vuex";
import { toast } from 'vue3-toastify';
export default {
  components: { Swiper, SwiperSlide},
  data() {
    return {
      baseUrl: process.env.BASE_URL,
      modules: [Autoplay],
      categoryListData: {},
      topLines: [],
      checkTokenLoggedIn:null,
      // allCartData:{},
      // cartSubTotal: 0,
      // cartTotal: 0,
      openSearch:false,
      serachProduct: '',
      windowWidth: window.innerWidth
    };
  },
  computed: {
    totalCartCount () {
      return this.$store.state.customer.totalCartCount
    }
  },
  watch: {
    // totalCartCount () {
    //   let vueInstance = this;
    //   const authData = vueInstance.$store.getters['auth/getAuthData']
    //   if(authData.token) { 
    //    vueInstance.getAddedToCartData()
    //   }
    // },
    '$store.state.auth.authData.token'(value, oldValue) {
      if(value){
        this.checkTokenLoggedIn= true
        this.getCartCountData();
      }
    },
    '$route' () {
      $("#primaryMenu").css("visibility", "hidden")      
      $('#primaryMenu').removeClass('show');      
      $('.offcanvas-backdrop').removeClass('show');      
      $('.rightside-box').removeClass('fade');      
      $('.offcanvas-backdrop').remove();      
    }
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
  created() {
      this.getcategoryList();
      this.getTopLinesList();
  },
  methods: {
    async getcategoryList() { 
      let vueInstance = this;
      await vueInstance.actionGetCategories().then((res) => {
          if (res.error == true) {
              return false;
          }
          vueInstance.categoryListData = res.response;
      })
      const authData = await vueInstance.$store.getters['auth/getAuthData']
      if(authData.token) {
        vueInstance.checkTokenLoggedIn= vueInstance.$store.state.auth.authData.token;
        vueInstance.getCartCountData();
      }
    },
    async getTopLinesList() { 
      let vueInstance = this;
      await vueInstance.actionGetTopLines().then((res) => {
          if (res.error == true) {
              return false;
          }
          vueInstance.topLines = res.response;
      })
    },
    // openSearch(){
    //   $('.search-full').addClass('open')
    // },
    async getCartCountData(){  
      let vueInstance = this;      
      await vueInstance.actionProductCartCount().then(results => {
          if(results.error == true){
              return false;
          }
          vueInstance.totalCartCount = results.response.count
          // if(vueInstance.totalCartCount > 0) vueInstance.getAddedToCartData()
      }).catch((err) => {
          return false;
      })
    },
    // async getAddedToCartData(){ 
    //   let vueInstance = this;
    //   await vueInstance.actionGetCartListData().then(results => {
          
    //       if(results.error == true){
    //         toast.error(results.message);
    //         return false;
    //       }
    //       let cartData = results.response
    //       vueInstance.allCartData = cartData.items;
    //       vueInstance.cartSubTotal = cartData.cartSubTotal;
    //       // vueInstance.discountAmount = cartData.coupon_discount;
    //       vueInstance.cartTotal = Math.floor(cartData.cartTotal);
          
    //   }).catch((err) => {
    //     return false;
    //   })
    // },
    async deleteProduct(id){
      let vueInstance = this;
      vueInstance.actionDeleteCartProduct({id:id}).then(results => {
        if(results.error == true){
            return false;
        }
        // vueInstance.allCartData = vueInstance.allCartData.filter((p, i) => {
        //   return i !== id;
        // });
        toast.success(results.message);
        // vueInstance.getAddedToCartData();
            
        }).catch((err) => {
          toast.error(err.message);
          return false;
      })
    },
    openSearchBox(){
      this.serachProduct= ''
      this.openSearch= true
    },
    closeSearchBox(){
      this.openSearch= false
    },
    async logout() {
      this.$store.state.customer.totalCartCount = 0;
      this.$store.state.customer.addedProducts = {};
      this.checkTokenLoggedIn= false
      this.$store.dispatch("auth/logout");
    },
    ...mapActions("customer", {
      actionGetCartListData: "getCartListData",
    }),
    ...mapActions("customer", {
      actionDeleteCartProduct: "deleteCartProduct",
    }),
    ...mapActions("customer", {
      actionProductCartCount: "productCartCount",
    }),
    ...mapActions("customer", {
      actionGetCategories: "getCategories",
    }),
    ...mapActions("customer", {
      actionGetTopLines: "getTopLines",
    }),
  },
};
</script>
<style scoped>
.container {
  padding: 2em;
}
.inotification-slider {
  height: 25px;
}
.v-slider-frame {
  border: 1px solid #4BB3FD;
  height: 50px;
  overflow: hidden;
  text-align: center;
}
.swiper-vertical .swiper-wrapper{
  text-align: center;
}
.timer-notification{
  text-align: center;
}
ul.v-slides {
  list-style-type: none;
  transform: translateY(50px);
  padding:0;
}
.v-slide {
  font-size: 24px;
  line-height: 50px;
  color: #4BB3FD;
}
header .header-top {
    padding: 10px 0;
    background-color: #7F2622
}
</style>
