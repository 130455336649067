<template>
  <header-component/>
  <!-- Breadcrumb Section Start -->
  <BreadscrumbSection pageName="Checkout" />
  <!-- Breadcrumb Section End -->

  <!-- Checkout section Start -->
  <section class="checkout-section-2 section-b-space">
    <div class="container-fluid-lg">
      <div class="row g-sm-4 g-3">
        <div class="col-lg-8">
          <div class="left-sidebar-checkout">
            <div class="checkout-detail-box">
              <ul>
                <li>
                  <div class="checkout-icon">
                    <lord-icon target=".nav-item" src="https://cdn.lordicon.com/ggihhudh.json" trigger="loop-on-hover" colors="primary:#121331,secondary:#646e78,tertiary:#0baf9a" class="lord-icon"> </lord-icon>
                  </div>
                  <div class="checkout-box">
                    <div class="checkout-title">
                      <h4>Delivery Address</h4>
                      <button class="btn theme-bg-color text-white btn-sm fw-bold mt-0" @click.prevent="addressAdd">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus me-2"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg> Add New Address
                      </button>
                    </div>                  

                    <div class="checkout-detail">
                      <div class="row g-4">
                        <div v-for="(addresseData, index) in addressessData" :key="index" class="col-xxl-6 col-lg-12 col-md-6">
                          <div class="delivery-address-box">
                            <div>
                              {{  setDefaultAddress(addresseData.id,addresseData.default_address) }}
                              <div class="form-check">
                                <input class="form-check-input" type="radio" :checked="addresseData.default_address" :value="addresseData.id" @change="changeDefultAddress($event)" name="defultAddress" />
                              </div>

                              <div class="label">
                                <label>{{ addresseData.type }}</label>
                              </div>

                              <ul class="delivery-address-detail">
                                <li>
                                  <h4 class="fw-500">{{ addresseData.first_name }} {{ addresseData.last_name }}</h4>
                                </li>

                                <li>
                                  <p class="text-content"><span class="text-title">Address : </span>
                                    {{ addresseData.address_line_1 }}, {{ addresseData.address_line_2 }} {{ addresseData.city }}, 
                                    {{ addresseData.state }}
                                  </p>
                                </li>

                                <li>
                                  <h6 class="text-content"><span class="text-title">Pin Code :</span> {{ addresseData.zip_code }}</h6>
                                </li>

                                <li>
                                  <h6 class="text-content mb-0"><span class="text-title">Phone :</span> {{ addresseData.phone }}</h6>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="checkout-icon">
                    <lord-icon target=".nav-item" src="https://cdn.lordicon.com/oaflahpk.json" trigger="loop-on-hover" colors="primary:#0baf9a" class="lord-icon"> </lord-icon>
                  </div>
                  <div class="checkout-box">
                    <div class="checkout-title">
                      <h4>Payment Option</h4>
                    </div>

                    <div class="checkout-detail">
                      <div class="row g-4">
                        <div class="col-xxl-6 payment-opt-hide">
                          <div class="delivery-option">
                            <div class="delivery-category">
                              <div class="shipment-detail">
                                <div class="form-check mb-0 custom-form-check show-box-checked">
                                  <input class="form-check-input" type="radio" v-model="paymentType" value="UPI" id="UPI" />
                                  <label class="form-check-label" for="UPI">UPI</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-xxl-6 payment-opt-hide">
                          <div class="delivery-option">
                            <div class="delivery-category">
                              <div class="shipment-detail">
                                <div class="form-check custom-form-check hide-check-box">
                                  <input class="form-check-input" type="radio" v-model="paymentType" value="DBCRD" id="DBCRD" />
                                  <label class="form-check-label" for="DBCRD">Debit Card</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-xxl-6 payment-opt-hide">
                          <div class="delivery-option">
                            <div class="delivery-category">
                              <div class="shipment-detail">
                                <div class="form-check custom-form-check hide-check-box">
                                  <input class="form-check-input" type="radio" v-model="paymentType" value="CRDC" id="CRDC" />
                                  <label class="form-check-label" for="CRDC">Credit Card</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-xxl-6 payment-opt-hide">
                          <div class="delivery-option">
                            <div class="delivery-category">
                              <div class="shipment-detail">
                                <div class="form-check mb-0 custom-form-check show-box-checked">
                                  <input class="form-check-input" type="radio" v-model="paymentType" value="NBK" id="NBK" />
                                  <label class="form-check-label" for="NBK">Net Banking</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-xxl-12">
                          <div class="delivery-option">
                            <div class="delivery-category">
                              <div class="shipment-detail">
                                <div class="form-check mb-0 custom-form-check show-box-checked">
                                  <input class="form-check-input" type="radio" v-model="paymentType" value="COD" id="cash" />
                                  <label class="form-check-label" for="cash">Cash On Delivery</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- <div class="col-12 future-box">
                          <div class="future-option">
                            <div class="row g-md-0 gy-4">
                              <div class="col-md-6">
                                <div class="delivery-items">
                                  <div>
                                    <h5 class="items text-content"><span>3 Items</span>@ ₹693.48</h5>
                                    <h5 class="charge text-content">
                                      Delivery Charge ₹34.67
                                      <button type="button" class="btn p-0" data-bs-toggle="tooltip" data-bs-placement="top" title="Extra Charge">
                                        <i class="fa-solid fa-circle-exclamation"></i>
                                      </button>
                                    </h5>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-6">
                                <form class="form-floating theme-form-floating date-box">
                                  <input type="date" class="form-control" />
                                  <label>Select Date</label>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </li>

                <!-- <li>
                  <div class="checkout-icon">
                    <lord-icon target=".nav-item" src="https://cdn.lordicon.com/qmcsqnle.json" trigger="loop-on-hover" colors="primary:#0baf9a,secondary:#0baf9a" class="lord-icon"> </lord-icon>
                  </div>
                  <div class="checkout-box">
                    <div class="checkout-title">
                      <h4>Payment Option</h4>
                    </div>

                    <div class="checkout-detail">
                      <div class="accordion accordion-flush custom-accordion" id="accordionFlushExample">
                        <div class="accordion-item">
                          <div class="accordion-header" id="flush-headingFour">
                            <div class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour">
                              <div class="custom-form-check form-check mb-0">
                                <label class="form-check-label" for="cash">
                                  <input class="form-check-input mt-0" type="radio" v-model="paymentType" value="COD" id="cash" /> Cash On Delivery</label>
                              </div>
                            </div>
                          </div>
                          <div id="flush-collapseFour" class="accordion-collapse collapse show" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                              <p class="cod-review">Pay digitally with SMS Pay Link. Cash may not be accepted in COVID restricted areas. <a href="javascript:void(0)">Know more.</a></p>
                            </div>
                          </div>
                        </div>

                        <div class="accordion-item">
                          <div class="accordion-header" id="flush-headingTwo">
                            <div class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo">
                              <div class="custom-form-check form-check mb-0">
                                <label class="form-check-label" for="banking"><input class="form-check-input mt-0" type="radio"  v-model="paymentType" id="banking" />Pay Online</label>
                              </div>
                            </div>
                          </div>
                          <div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                              <h5 class="text-uppercase mb-4">Select Your Bank</h5>
                              <div class="row g-2">
                                <div class="col-md-6">
                                  <div class="custom-form-check form-check">
                                    <input class="form-check-input mt-0" type="radio" id="bank1" />
                                    <label class="form-check-label" for="bank1">Industrial & Commercial Bank</label>
                                  </div>
                                </div>

                                <div class="col-md-6">
                                  <div class="custom-form-check form-check">
                                    <input class="form-check-input mt-0" type="radio" id="bank2" />
                                    <label class="form-check-label" for="bank2">Agricultural Bank</label>
                                  </div>
                                </div>

                                <div class="col-md-6">
                                  <div class="custom-form-check form-check">
                                    <input class="form-check-input mt-0" type="radio" name="flexRadioDefault" id="bank3" />
                                    <label class="form-check-label" for="bank3">Bank of America</label>
                                  </div>
                                </div>

                                <div class="col-md-6">
                                  <div class="custom-form-check form-check">
                                    <input class="form-check-input mt-0" type="radio" name="flexRadioDefault" id="bank4" />
                                    <label class="form-check-label" for="bank4">Construction Bank Corp.</label>
                                  </div>
                                </div>

                                <div class="col-md-6">
                                  <div class="custom-form-check form-check">
                                    <input class="form-check-input mt-0" type="radio" name="flexRadioDefault" id="bank5" />
                                    <label class="form-check-label" for="bank5">HSBC Holdings</label>
                                  </div>
                                </div>

                                <div class="col-md-6">
                                  <div class="custom-form-check form-check">
                                    <input class="form-check-input mt-0" type="radio" name="flexRadioDefault" id="bank6" />
                                    <label class="form-check-label" for="bank6">JPMorgan Chase & Co.</label>
                                  </div>
                                </div>

                                <div class="col-12">
                                  <div class="select-option">
                                    <div class="form-floating theme-form-floating">
                                      <select class="form-select theme-form-select" aria-label="Default select example">
                                        <option value="hsbc">HSBC Holdings</option>
                                        <option value="loyds">Lloyds Banking Group</option>
                                        <option value="natwest">Nat West Group</option>
                                        <option value="Barclays">Barclays</option>
                                        <option value="other">Others Bank</option>
                                      </select>
                                      <label>Select Other Bank</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li> -->
              </ul>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="right-side-summery-box">
            <div class="summery-box-2">
              <div class="summery-header">
                <h3>Order Summery</h3>
              </div>

              <ul class="summery-contain">
                <li v-for="(cartData,index) in allCartData" :key="index" >
                  <img :src="cartData.products.images[0].image_url" class="img-fluid blur-up lazyloaded checkout-image" alt="" />
                  <h4>{{ cartData.products.name}} <span>X {{ cartData.quantity }}</span></h4>
                  <h4 class="price">₹{{(cartData.products.variant.sale_price * cartData.quantity).toFixed(2) }}</h4>
                </li>

              </ul>

              <ul class="summery-total">
                <li>
                  <h4>Subtotal</h4>
                  <h4 class="price">₹{{ cartSubTotal }}</h4>
                </li>
                
                <li>
                  <h4>Shipping</h4>
                  <h4 class="price">₹{{ shippingFee }}</h4>
                </li>

                <!-- <li>
                  <h4>Tax</h4>
                  <h4 class="price">₹{{ taxable }}</h4>
                </li> -->

                <li v-if="discountAmount > 0">
                  <h4 class="discount-line">{{ codeApply }} Discount</h4>
                  <h4 class="price">(-) {{ discountAmount }}</h4>
                </li>

                <li class="list-total">
                  <h4>Total </h4>
                  <h4 class="price">₹{{ cartTotal - discountAmount }}</h4>
                </li>
              </ul>
            </div>

            

            <div class="checkout-offer">
              <div class="right-side-summery-box">
                <div class="summery-header">
                  <h3>Wallet</h3>
                </div>
              </div>
              <hr>

              <ul class="offer-detail" style="list-style-type: none;">
                <li>
                  <span style="font-size: 16px; position: relative;"><input v-if="walletBalance > 0" type="checkbox" v-model="useWalletAmount" :value="true" class="checkbox_animated" id="fruit">
                    Your wallet balance ₹{{ walletBalance }}</span>
                </li>
              </ul>
            </div>

            <button v-if="allCartData.length > 0" @click="orderSuccess" class="btn theme-bg-color text-white btn-md w-100 mt-4 fw-bold">
              <i v-if="spinnerShow" class="fa fa-spinner fa-spin"></i>Place Order
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Teleport to="body">
    <div class="modal" id="addressEditAdd">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{  addressTitle }}</h5>
            <button type="button" class="btn-close" @click="closeModel"></button>
          </div>
          <div class="modal-body">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-6">
                  <label for="recipient-name" class="col-form-label">First Name</label>
                  <input v-model="firstName" @blur="v$.firstName.$touch" type="text" class="form-control">
                  <div class="input-errors" v-for="(error, index) of v$.firstName.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <label for="recipient-name" class="col-form-label">Last Name</label>
                  <input v-model="lastName" @blur="v$.lastName.$touch" type="text" class="form-control">
                  <div class="input-errors" v-for="(error, index) of v$.lastName.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label for="recipient-name" class="col-form-label">Email</label>
                  <input v-model="emailAddress" @blur="v$.emailAddress.$touch" type="text" class="form-control">
                  <div class="input-errors" v-for="(error, index) of v$.emailAddress.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <label for="recipient-name" class="col-form-label">Phone Number</label>
                  <input v-model="phone" @keyup="acceptNumber" @blur="v$.phone.$touch" type="text" class="form-control">
                  <div class="input-errors" v-for="(error, index) of v$.phone.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label for="recipient-name" class="col-form-label">Type</label>
                  <select class="form-select form-control" v-model="addressType">
                    <option value="HOME">Home</option>
                    <option value="OFFICE">Office</option>
                    <option value="FRIEND">Friend</option>
                  </select>
                  <div class="input-errors" v-for="(error, index) of v$.addressType.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <label for="recipient-name" class="col-form-label">House no, Flat, Building</label>
                  <input v-model="addressLine1" @blur="v$.addressLine1.$touch" type="text" class="form-control">
                  <div class="input-errors" v-for="(error, index) of v$.addressLine1.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label for="recipient-name" class="col-form-label">Area, Street, Sector</label>
                  <input v-model="addressLine2" type="text" class="form-control">
                </div>
                <div class="col-md-6">
                  <label for="recipient-name" class="col-form-label">City</label>
                  <input v-model="city" @blur="v$.city.$touch" type="text" class="form-control">
                  <div class="input-errors" v-for="(error, index) of v$.city.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label for="recipient-name" class="col-form-label">State</label>
                  <input v-model="state" @blur="v$.state.$touch" readonly type="text" class="form-control">
                  <div class="input-errors" v-for="(error, index) of v$.state.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <label for="recipient-name" class="col-form-label">Zip Code</label>
                  <input v-model="zipCode" @blur="v$.zipCode.$touch" type="text" class="form-control">
                  <div class="input-errors" v-for="(error, index) of v$.zipCode.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                  </div>                  
                </div>
                <div class="row mt-4">
                  <div class="col-md-12">
                    <input v-model="defultAddress" :value=true class="checkbox_animated check-box form-check-input" id="fruit" type="checkbox" style="margin-top:-1px" />
                    <label for="fruit" class="form-check-label">default Address</label>
                  </div>
                </div> 
              </div>
              
            </div>
          </div>
          
          <div class="modal-footer">
            <div class="error-msg">{{ addressError }}</div>
            <button type="button" class="btn" @click.prevent="closeModel">Close</button>
            <button type="button" class="btn" @click.prevent="addressSubmit"><i v-if="spinnerShow" class="fa fa-spinner fa-spin"></i>Save</button>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
  <!-- Checkout section End -->

 
</template>

<script>
import { mapActions } from "vuex";
import { toast } from 'vue3-toastify';
import { useVuelidate } from '@vuelidate/core'
import BreadscrumbSection from '@/components/forntEnd/BreadscrumbSection.vue';
import HeaderComponent from '@/components/template/front/HeaderComponent.vue';
import { required, email, helpers  } from '@vuelidate/validators'
export default {
  components: {  BreadscrumbSection, HeaderComponent},
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      addressessData: [],
      addressError: null,
      modalShow: false,
      spinnerShow: false,
      defuleCheckVal: true,
      addressTitle: 'Add your new address',
      zipCode: "",
      addressLine1: "",
      addressLine2: "",
      state: "",
      city: "",
      emailAddress: "",
      firstName: "",
      lastName: "",
      phone: "",
      addressType: "HOME",
      allCartData:{},
      cartSubTotal: 0,
      salesTax:0,
      taxable:0,
      shippingFee:0,
      discountAmount:0,
      cartTotal:0,
      walletBalance:0,
      useWalletAmount: false,
      defaultAddress: "",
      paymentType: "COD",
      note: "Note",
      defultAddress: false,
      discountId:'',
      codeApply:'',
      referralCode:'',
    };
  },
  validations: {
    zipCode: {
      required: helpers.withMessage("Please enter valid zip code", required)
    },
    addressLine1: {
      required: helpers.withMessage("Please enter your House no, Flat, Building", required)
    },
    city: {
      required: helpers.withMessage("Please enter city", required)
    },
    state: {
      required: helpers.withMessage("Please enter state ", required)
    },
    emailAddress: {
      required: helpers.withMessage("Please enter your email id", required),
      email: helpers.withMessage("Please enter your valid email id", email)
    },
    firstName: {
      required: helpers.withMessage("Please enter your first name", required)
    },
    lastName: {
      required: helpers.withMessage("Please enter your last name", required)
    },
    phone: {
      required: helpers.withMessage("Please enter your valid phone number", required)
    },
    addressType: {
      required: helpers.withMessage("Select address type", required)
    },
  },
  created() {
    this.getAddressesData();
    this.getAddedToCartData();
  },
  methods: {
    setDefaultAddress(addressId,defaultAddress){
      defaultAddress ? this.defaultAddress = addressId : ''
    },
    async orderSuccess(){
      let vueInstance = this;
      if(!vueInstance.defaultAddress){
        toast.error("Please select your address");
        return false;
      }
      if(!vueInstance.paymentType){
        toast.error("Please select Payment type");
        return false;
      }
      $(".btn").prop("disabled", true);
      vueInstance.spinnerShow = true;

      if(vueInstance.paymentType != 'COD'){
        const authData = vueInstance.$store.getters['auth/getAuthData']
        const url = `https://kingdomofgrains.com/webservices/payment?token=${authData.token}&payment_type=${vueInstance.paymentType}&address_id=${vueInstance.defaultAddress}&referral_code=${vueInstance.referralCode}&coupon_id=${vueInstance.discountId}&use_wallet_amount=${vueInstance.useWalletAmount}`;
        window.location.replace(url);
        return false;
      }
      await vueInstance.actionCheckoutProcess({
        coupon_id: vueInstance.discountId,
        payments: {
          type: vueInstance.paymentType,
          card_number: "",
          expiration_date: "",
          card_code: "",
          save_card: false
        },
        referral: {
          code: vueInstance.referralCode
        },
        delivery_address: {
          id: vueInstance.defaultAddress
        },
        delivery_slot: {
          id: 1,
          date: "2023-05-20",
          in_time: "10:00:00",
          out_time: "12:00:00"
        },
        use_wallet_amount: vueInstance.useWalletAmount,
        note: vueInstance.note,
      }).then((results) => {
        if (results.error == true) {
            toast.error(results.message);
            vueInstance.spinnerShow = false;
            $(".btn").prop("disabled", false);
            return false
        }
        toast.success(results.message);
        localStorage.removeItem("discountAmount");
        localStorage.removeItem("discountId");
        localStorage.removeItem("discountType");
        vueInstance.$router.push({name: 'OrderSuccess'})
        return false;
      }).catch((err) => {
        toast.error(err.message);
        vueInstance.spinnerShow = false;
        $(".btn").prop("disabled", false);
        return false;
      });
    },

    async getAddedToCartData(){
      let vueInstance = this;
      await vueInstance.actionGetCartListData().then(results => {

          if(results.error == true){
            toast.error(results.message);
            vueInstance.allCartData={}
            vueInstance.cartSubTotal= 0
            vueInstance.salesTax=0
            vueInstance.taxable=0
            vueInstance.shippingFee=0
            vueInstance.discountAmount=0
            vueInstance.cartTotal=0
            return false;
          }
          let cartData = results.response
          vueInstance.allCartData = cartData.items;
          vueInstance.cartSubTotal = cartData.cartSubTotal;
          vueInstance.salesTax = cartData.salesTax;
          vueInstance.taxable = cartData.taxable;
          vueInstance.shippingFee = cartData.deliveryFee;
          // vueInstance.discountAmount = cartData.discountAmount;
          vueInstance.walletBalance = cartData.wallet_balance;
          vueInstance.cartTotal = Math.floor(cartData.cartTotal);
          vueInstance.discountAmount = localStorage.getItem("discountAmount");
          vueInstance.codeApply = localStorage.getItem("discountType");
          vueInstance.referralCode = localStorage.getItem("referralCode");
          if(localStorage.getItem("discountType") == 'COUPON')
            vueInstance.discountId = localStorage.getItem("discountId");
      }).catch((err) => {
        toast.error(err.message);
        return false;
      })
    },

    async changeDefultAddress(event){
      let vueInstance = this;
      vueInstance.defaultAddress = event.target.value
      return false;
      // alert(data);
      // await vueInstance.actionDeliveryAddressUpdate({
      //     default_address: data,
      //   }).then((results) => {
      //     if (results.error == true) {
      //         toast.error(results.message);
      //         return false
      //     }
      //     toast.success(results.message);
      //     return false;
      //   }).catch((err) => {
      //     toast.error(err.message);
      //     return false;
      //   });
    },

    async getAddressesData() {
      let vueInstance = this;
      await vueInstance.actionGetAddresses().then((results) => {
        if (results.error == true) {
          return false;
        }
          vueInstance.addressessData = results.response;
        })
        .catch((err) => {
          return false;
        });
    },

    async addressSubmit(){ 
      let vueInstance = this;
      vueInstance.addressError = null;
      vueInstance.v$.$touch();
      if (!vueInstance.v$.$invalid) {
        $(".btn").prop("disabled", true);
        vueInstance.spinnerShow = true;
        var phoneNumber = vueInstance.phone.replace(/[^\d]/g, "");
        var phoneNo = phoneNumber.substr(0, 3) + phoneNumber.substr(3, 3) + phoneNumber.substr(6);
        
        await vueInstance.actionDeliveryAddressUpdate({
          address_line_1: vueInstance.addressLine1,
          address_line_2: vueInstance.addressLine2,
          zip_code: vueInstance.zipCode,
          city: vueInstance.city,
          state: vueInstance.state,
          first_name: vueInstance.firstName,
          last_name: vueInstance.lastName,
          email: vueInstance.emailAddress,
          phone: phoneNo,
          type: vueInstance.addressType,
          default_address: vueInstance.defultAddress,
        }).then((results) => {
            $("html, body").animate({ scrollTop:0 }, "slow");
            vueInstance.spinnerShow = false;
            $(".btn").prop("disabled", false);
            if (results.error == true) {
                $("html, body").animate({ scrollTop:0 }, "slow");
                // toast.error(results.message);
                vueInstance.spinnerShow = false;
                $(".btn").prop("disabled", false);
                vueInstance.addressError = results.message;
                return false
            }
            toast.success(results.message);
            vueInstance.spinnerShow = false;
            $(".btn").prop("disabled", false);
            // vueInstance.getAddressesData();
            vueInstance.addressessData = results.response;
            vueInstance.closeModel()
            return false;
        })
        .catch((err) => {
            $("html, body").animate({ scrollTop:0 }, "slow");
            toast.error(err.message);
            vueInstance.spinnerShow = false;
            $(".btn").prop("disabled", false);
            vueInstance.closeModel()
            return false;
        });
      }
    },

    acceptNumber() {
        var x = this.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        this.phone = !x[2] ? x[1] : x[1] + x[2] + (x[3] ? x[3] : '');
    },

    addressAdd(){ 
      let vueInstance = this;
      vueInstance.addressError = null;
      vueInstance.zipCode = "";
      vueInstance.city = "";
      vueInstance.state = "Haryana";
      vueInstance.addressLine1 = ""
      vueInstance.addressLine2 = "";
      vueInstance.emailAddress = "";
      vueInstance.firstName = "";
      vueInstance.lastName ="";
      vueInstance.phone = "";
      vueInstance.addressType = "HOME";
      $('#addressEditAdd').show()
    },

    closeModel(){ 
      $('#addressEditAdd').hide()
    },
    ...mapActions("customer", {
      actionGetAddresses: "addresses",
    }),

    ...mapActions("customer", {
      actionDeliveryAddressUpdate: "deliveryAddressUpdate",
    }),

    ...mapActions("customer", {
      actionCheckoutProcess: "submitCheckOut",
    }),

    ...mapActions("customer", {
      actionGetCartListData: "getCartListData",
    }),

    ...mapActions("customer", {
      actionCheckoutTest: "checkoutTest",
    }),
  },
};
</script>
<!-- vueInstance.$router.push({name: 'OrderSuccess'}) -->
<style scoped>
.user-dashboard-section .dashboard-right-sidebar .dashboard-address .address-box>div .label .btn{
  position: absolute;
  top: 0;
  right: 0;
  background-color:#7F2622;
  padding: 2px 8px;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  letter-spacing: 0.8px;
}
.label label{
  position: absolute;
  top: 0;
  right: 0;
  background-color:#7F2622;
  padding: 2px 8px;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  letter-spacing: 0.8px;
}
.user-dashboard-section .dashboard-right-sidebar .dashboard-address .address-box .button-group button:hover {
  background-color: #7F2622 !important;
  color: #7F2622 !important;
}
.modal-footer button:hover {
  background-color: #7F2622 !important;
  color: #fff;
}
.theme-bg-color {
  background: #7F2622 !important;
  background-color:#7F2622;
}
.form-check .form-check-input:checked[type="radio"] {
  background-color: #7F2622 !important;
  color: #7F2622 !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.checkbox_animated:before {
    content: "";
    position: absolute;
    width: 12px;
    height: 6px;
    /* -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75); */
    transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75), -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    -webkit-transform: rotate(-45deg) scale(0, 0);
    transform: rotate(-45deg) scale(0, 0);
    left: 4px;
    top: 4px;
    z-index: 1;
    border: 2px solid #7F2622;
    border-top-style: none;
    border-right-style: none;
  }
  ul {
    list-style-type: none;
  }
  .discount-line{
    text-transform: lowercase;
  }
  .discount-line:first-letter {
    text-transform: uppercase;
  }
</style>


