<template>
  <header-component/>
  <!-- Breadcrumb Section Start -->
  <breadscrumb-section pageName="Cart"/>
  <!-- Breadcrumb Section End -->

  <!-- Cart Section Start -->
  <section class="cart-section section-b-space"> <div id="a"></div>
    <div class="container-fluid-lg">
      <div class="row g-sm-5 g-3" v-if="!isCartEmpty">
        <div class="col-xxl-9">
          <div class="cart-table">
            <div class="table-responsive-xl">
              <table class="table">
                <tbody>

                  <tr v-for="(cartData,index) in allCartData" :key="index" class="product-box-contain">
                    <td class="product-detail">
                      <div class="product border-0">
                        <router-link :to="{ name: 'ProductDetails',params:{productSlug: cartData.products.slug}}" class="product-image">
                          <img :src="cartData.products.images[0].image_url" class="img-fluid blur-up lazyload" alt="" />
                        </router-link>
                        <div class="product-detail">
                          <ul>
                            <li class="name">
                              <router-link :to="{ name: 'ProductDetails',params:{productSlug: cartData.products.slug}}">{{ cartData.products.name}}</router-link>
                            </li>

                            <li class="text-content"><span class="text-title">Sold By:</span> Kingdom</li>

                            <li class="text-content"><span class="text-title">Pack Size:</span> {{ cartData.products.variant.unit_value}} {{ cartData.products.variant.unit}}</li>
                            <li class="text-content">
                              <span class="text-title">Pisai:</span> 
                              {{ cartData.products.grain_attribute.value}} 
                            </li>

                            <li>
                              <h5 class="text-content d-inline-block">Price :</h5>
                              <span>₹{{ cartData.products.variant.sale_price}}</span>
                              <span class="text-content">₹{{ cartData.products.variant.retail_price}}</span>
                            </li>

                            <li>
                              <h5 class="saving theme-color">Saving : ₹{{ cartData.products.variant.retail_price - cartData.products.variant.sale_price}}</h5>
                            </li>

                            <li class="quantity-price-box">
                              <div class="cart_qty">
                                <div class="input-group">
                                  <button type="button" @click.prevent="changeQuantity('decrease',cartData.products.id,cartData.quantity)" class="btn qty-left-minus" data-type="minus" data-field="">
                                    <i class="fa fa-minus ms-0" aria-hidden="true"></i>
                                  </button>
                                  <input onKeyDown="if(this.value ==2) return false;" readonly :value="cartData.quantity" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" class="form-control input-number qty-input" type="text" name="quantity" value="0" />
                                  <button @click.prevent="changeQuantity('increase',cartData.products.id,cartData.quantity)" type="button" class="btn qty-right-plus" data-type="plus" data-field="">
                                    <i class="fa fa-plus ms-0" aria-hidden="true"></i>
                                  </button>
                                </div>
                              </div>
                            </li>

                            <li>
                              <h5>Total: ₹{{(cartData.products.variant.sale_price * cartData.quantity).toFixed(2) }}</h5>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>

                    <td class="price">
                      <h4 class="table-title text-content">Price</h4>
                      <h5>₹{{ cartData.products.variant.sale_price }} <del class="text-content">₹{{cartData.products.variant.retail_price}}</del></h5>
                      <h6 class="theme-color">You Save : ₹{{ cartData.products.variant.retail_price - cartData.products.variant.sale_price}}</h6>
                    </td>

                    <td class="quantity">
                      <h4 class="table-title text-content">Qty</h4>
                      <div class="quantity-price">
                        <div class="cart_qty">
                          <div class="input-group">
                            <button @click.prevent="changeQuantity('decrease',cartData.id,cartData.quantity)" type="button" class="btn qty-left-minus" data-type="minus" data-field="">
                              <i class="fa fa-minus ms-0" aria-hidden="true"></i>
                            </button>
                            <input readonly :value="cartData.quantity" class="form-control input-number qty-input ms-1" type="text" />
                            <button @click.prevent="changeQuantity('increase',cartData.id,cartData.quantity)" type="button" class="btn qty-right-plus ms-1" data-type="plus" data-field="">
                              <i class="fa fa-plus ms-0" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </td>

                    <td class="subtotal">
                      <h4 class="table-title text-content">Total</h4>
                      <h5>₹{{(cartData.products.variant.sale_price * cartData.quantity).toFixed(2) }}</h5>
                    </td>

                    <td class="save-remove">
                      <h4 class="table-title text-content">Action</h4>
                      <a class="save notifi-wishlist" @click="addToWish(cartData.product_id,cartData.variant_id)" href="javascript:void(0)">Move to wish list</a>
                      <a class="remove close_button"  @click.prevent="deleteProduct(cartData.id)" href="javascript:void(0)">Remove</a>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-xxl-3">
          <div class="summery-box p-sticky">
            <div class="summery-header">
              <h3>Cart Total</h3>
            </div>
            
            <div class="summery-contain">
              <div class="form-check form-check-inline" style="margin-right: .2rem !important;">
                <input :disabled="readonlyCode" @change="changeCode($event,'coupon')" v-model="codeApply" class="form-check-input" type="radio" id="inlineRadio1" value="COUPON">
                <label class="form-check-label" for="inlineRadio1">Coupon Code</label>
              </div>
              <div class="form-check form-check-inline">
                <input :disabled="readonlyCode" @change="changeCode($event,'refer')" v-model="codeApply" class="form-check-input" type="radio" id="inlineRadio2" value="REFERRAL">
                <label class="form-check-label" for="inlineRadio2">Referral Code</label>
              </div>
              <div class="coupon-cart mt-2">
                <h6 class="text-content mb-2">Code Apply</h6>
                <div class="mb-3 coupon-box input-group">
                  
                  <input :readonly="readonlyCode" type="text" v-model.trim="couponCode" class="form-control" placeholder="Enter Code" />
                  <button v-if="!readonlyCode" @click.prevent="submitCouponCode" class="btn btn-animation proceed-btn fw-bold btn-sm">
                    <i v-if="spinnerShow" class="fa fa-spinner fa-spin"></i>Apply
                  </button>
                  <button v-else @click.prevent="cancelCouponCode" class="btn btn-animation proceed-btn fw-bold btn-sm">
                    <i v-if="spinnerShow" class="fa fa-spinner fa-spin"></i>Cancel
                  </button>
                </div>
              </div>
              <ul>
                <li>
                  <h4>Subtotal</h4>
                  <h4 class="price">₹{{ cartSubTotal }}</h4>
                </li>

                <li v-if="discountAmount > 0">
                  <h4 class="text-transform: capitalize;">{{ codeApply }} Discount</h4>
                  <h4 class="price">(-) {{ discountAmount }}</h4>
                </li>

                <li class="align-items-start">
                  <h4>Shipping</h4>
                  <h4 class="price text-end">₹{{ shippingFee }}</h4>
                </li>
              </ul>
            </div>

            <ul class="summery-total">
              <li class="list-total border-top-0">
                <h4>Total</h4>
                <h4 class="price theme-color">₹{{ cartTotal - discountAmount }}</h4>
              </li>
            </ul>

            <div class="button-group cart-button">
              <ul>
                <li>
                  <button @click="checkOut" class="btn btn-animation proceed-btn fw-bold">Process To Checkout</button>
                </li>

                <li>
                  <router-link :to="{ name: 'Home'}" class="btn btn-light shopping-button text-dark btn-primary"><i class="fa-solid fa-arrow-left-long"></i>Return To Shopping</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-sm-5 g-3" v-else>
        <div class="col-xxl-12">
          <img src="../../../../assets/images/empty-cart.png" style="width: 100%;" alt="" class="blur-up lazyload set-image" />
        </div>
      </div>
    </div>
  </section>
  <!-- Cart Section End -->
</template>
<script>
import BreadscrumbSection from '@/components/forntEnd/BreadscrumbSection.vue';
import HeaderComponent from '@/components/template/front/HeaderComponent.vue';
import { mapActions } from "vuex";
import { toast } from 'vue3-toastify';
import Swal from 'sweetalert2';
export default {
  components: { BreadscrumbSection, HeaderComponent},
  data() {
    return {
      allCartData:{},
      cartSubTotal: 0,
      salesTax:0,
      taxable:0,
      shippingFee:0,
      discountAmount:0,
      cartTotal:0,
      couponCode: '',
      spinnerShow: false,
      discountId:'',
      isCartEmpty:false,
      codeApply: 'COUPON',
      applyReferralCode: '',
      discountType: '',
      readonlyCode: false
    };
  },
  // beforeRouteEnter (to, from) {
  //   $(".onhover-div").css("visibility", "hidden")
  //   setTimeout(function(){ 
  //     $(".onhover-div").removeAttr("style")
  //   }, 6000);
  // },
  
  mounted () {
    this.getAddedToCartData();
    localStorage.removeItem("discountAmount");
    localStorage.removeItem("discountId");
    localStorage.removeItem("discountType");
    localStorage.removeItem("referralCode");
  },
  methods: {
    
    async getAddedToCartData(){
      let vueInstance = this;
      await vueInstance.actionGetCartListData().then(results => { 
          
          if(results.error == true){
            // toast.error(results.message);
            vueInstance.allCartData={}
            vueInstance.cartSubTotal= 0
            vueInstance.salesTax=0
            vueInstance.taxable=0
            vueInstance.shippingFee=0
            vueInstance.discountAmount=0
            vueInstance.cartTotal=0
            vueInstance.isCartEmpty=true
            return false;
          }
          let cartData = results.response
          vueInstance.allCartData = cartData.items;
          vueInstance.cartSubTotal = cartData.cartSubTotal;
          vueInstance.salesTax = cartData.salesTax;
          vueInstance.taxable = cartData.taxable;
          vueInstance.shippingFee = cartData.deliveryFee;
          vueInstance.discountAmount = cartData.discountAmount;
          vueInstance.cartTotal = Math.floor(cartData.cartTotal);
          if(vueInstance.allCartData.length == 0) vueInstance.isCartEmpty=true
          // vueInstance.allCartData.forEach((item) => {
          //   vueInstance.cartTotal += Math.floor(item.quantity * item.product_variant.sale_price);
          // });
      }).catch((err) => {
        // toast.error(err.message);
        return false;
      })
    },

    async changeCode(event, code){
      let vueInstance = this;
      
      if(code =='COUPON') vueInstance.codeApply = event.target.value
      if(code =='REFERRAL') vueInstance.codeApply = event.target.value
      return false;
    },

    async deleteProduct(id){
      let vueInstance = this;
      Swal.fire({
        title: 'Are you sure?',
        text: 'Want to remove?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          vueInstance.actionDeleteCartProduct({id:id}).then(results => {
            if(results.error == true){
                return false;
            }
            toast.success(results.message);
            vueInstance.getAddedToCartData();
                
            }).catch((err) => {
              toast.error(err.message);
              return false;
          })
        }
      });
    },

    async changeQuantity(actionType, productId, qty){ 
      var vueInstance = this;
      var changeQuantity;
      if(actionType ==="increase"){
          
        changeQuantity = parseInt(qty) + parseInt(1)
        // if(changeQuantity > 5){
        //     vueInstance.flashError("You can't select more than 5 quantities.", {
        //         timeout: 4000,
        //     });
        //     return false;
        // }
        await vueInstance.actionUpdateToProductQyt({
          cart_id: productId,
          quantity: changeQuantity,
        }).then((results) => {
          if (results.error == true) {
            toast.error(results.message);
            return false
          }
          vueInstance.getAddedToCartData();
          toast.success(results.message);
          return false;
        })
          
      }else{
        changeQuantity =  parseInt(qty) - parseInt(1)
        if(changeQuantity < 1){
          // toast.error("You can't select less than 1 quantity.");
          vueInstance.actionDeleteCartProduct({id:productId}).then(results => {
            if(results.error == true){
                return false;
            }
            // toast.success(results.message);
            vueInstance.getAddedToCartData();
                
            }).catch((err) => {
              toast.error(err.message);
              return false;
          })
          return false;
        }
        await vueInstance.actionUpdateToProductQyt({
          cart_id: productId,
            quantity: changeQuantity,
        }).then((results) => {
            if (results.error == true) {
              toast.error(results.message);
              return false
            }
            vueInstance.getAddedToCartData();
            toast.success(results.message);
            return false;
        })
      }
      return true
    },

    async submitCouponCode(){
        let vueInstance = this;

        if(!vueInstance.couponCode){
          toast.error("Please enter code");
          return false;
        }

        $(".btn").prop("disabled", true);
        vueInstance.spinnerShow = true;
        await vueInstance.actionCheckCouponCode({
          code: vueInstance.couponCode,
          type: vueInstance.codeApply,
        }).then((results) => {
          vueInstance.spinnerShow = false;
          vueInstance.applyReferralCode = '';
          vueInstance.discountType = '';
          if (results.error == true) {
            toast.error(results.message);
            $(".btn").prop("disabled", false);
            return false
          }
          toast.success(results.message);
          $(".btn").prop("disabled", false);
          vueInstance.discountType= vueInstance.codeApply;
          vueInstance.discountAmount= results.response.discount_value;
          
          if(vueInstance.codeApply == 'COUPON'){ 
            vueInstance.discountId= results.response.id;
          }else{ 
            vueInstance.applyReferralCode= results.response.code;
          }
          vueInstance.readonlyCode = true
          return;
        })
        .catch((err) => {
          toast.error(err.message);
          vueInstance.spinnerShow = false;
          $(".btn").prop("disabled", false);
          return false;
        });
    },

    async addToWish(productId,variantId){ 
      let vueInstance = this;          
      await vueInstance.actionAddToWishRemoveLater({
        variant_id: variantId,
        product_id: productId,
        wish_type: "ADD",
      }).then(results => {
        if (results.error == true) {
            toast.error(results.message);
            return false
        }
        toast.success(results.message);
        vueInstance.getAddedToCartData();
        return false;
      }).catch((err) => {
        toast.error(err.message);
        return false;
      });
    },

    checkOut(){
      let vueInstance = this;
      if(vueInstance.discountId){
        localStorage.setItem("discountType", vueInstance.discountType);
         localStorage.setItem("discountId", vueInstance.discountId);
         localStorage.setItem("discountAmount", vueInstance.discountAmount);
      }else if(vueInstance.applyReferralCode){
         localStorage.setItem("discountAmount", vueInstance.discountAmount);
         localStorage.setItem("discountType", vueInstance.discountType);
         localStorage.setItem("referralCode", vueInstance.couponCode);
      }
      vueInstance.$router.push({name: 'CheckOutPorcess'})
    },

    cancelCouponCode(){
      let vueInstance = this;
      vueInstance.spinnerShow = false;
      vueInstance.applyReferralCode = '';
      vueInstance.discountType = '';
      vueInstance.discountId = '';
      vueInstance.discountAmount= 0;
      vueInstance.couponCode= '';
      vueInstance.readonlyCode = false
    },
    
    ...mapActions("customer", {
        actionAddToWishRemoveLater: "addToWishRemoveLater",
    }),
    ...mapActions("customer", {
      actionGetCartListData: "getCartListData",
    }),
    ...mapActions("customer", {
      actionDeleteCartProduct: "deleteCartProduct",
    }),
    ...mapActions("customer", {
      actionUpdateToProductQyt: "updateToProductQyt",
    }),
    ...mapActions("customer", {
      actionCheckCouponCode: "checkCouponCode",
    }),    
  },
};
</script>
<style scoped>
.cart-table table tbody tr td .quantity-price {
  width: 78% !important;
}
.form-check .form-check-input:checked[type="radio"] {
  background-color: #7F2622 !important;
  color: #7F2622 !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
</style>
