<template>
    <body class="theme-color-3 dark" data-gr-ext-installed data-new-gr-c-s-check-loaded="14.1110.0">
        <header-component/>
        <router-view :key="$route.fullPath"/>

        <footer-component/>
    </body>
</template>

<script>

import FooterComponent from '../template/front/FooterComponent.vue';
import HeaderComponent from '../template/front/HeaderComponent.vue';
export default {
  components: { HeaderComponent, FooterComponent },
    setup() {
        
    },
}
</script>
<style>

</style>