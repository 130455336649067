<template>
    <section class="breadscrumb-section pt-0">
        <div class="container-fluid-lg">
            <div class="row">
                <div class="col-12">
                    <div class="breadscrumb-contain">
                        <h2 class="mb-2">{{ pageName ?? '' }}</h2>
                        <nav>
                            <ol class="breadcrumb mb-0">
                                <li class="breadcrumb-item">
                                    <router-link :to="{ name: 'Home' }">
                                        <i class="fa-solid fa-house"></i>
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item active">{{ pageName ?? '' }}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: ['pageName'],
}
</script>

