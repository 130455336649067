<template>
  <header-component/>
  <!-- Breadcrumb Section Start -->
  <breadscrumb-section pageName="Shop By Category"/>
   <!-- Breadcrumb Section End -->

  <!-- Shop Section Start -->
  <section class="section-b-space shop-section">
    <div class="container-fluid-lg">
      <div class="row">
        <div class="col-custome-3 wow fadeInUp">
          <div class="left-box">
            <div class="shop-left-sidebar">
              <div class="back-button">
                <h3><i class="fa-solid fa-arrow-left"></i> Back</h3>
              </div>

              <div class="filter-category">
                <div class="filter-title">
                  <h2>Filters</h2>
                  <!-- <a href="javascript:void(0)">Clear All</a> -->
                </div>
              </div>

              <div class="accordion custome-accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      <span>Categories</span>
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                    <div class="accordion-body">
                      <!-- <div class="form-floating theme-form-floating-2 search-box">
                        <input type="search" class="form-control" id="search" placeholder="Search .." />
                        <label for="search">Search</label>
                      </div>@click="categoryFilter()" -->

                      <ul class="category-list custom-padding custom-height">
                        <li v-for="(categoryData,index) in categoryListData" :key="index">
                          <div class="form-check ps-0 m-0 category-list-box">
                            <input v-model="selectedCaegoryId" :value="categoryData.id" class="checkbox_animated" type="checkbox" />
                            <label class="form-check-label" for="fruit">
                              <span class="name">{{categoryData.name}}</span>
                              <!-- <span class="number">({{ categoryData.products.length }})</span> -->
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      <span>Price</span>
                    </button>
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingThree">
                    <div class="accordion-body">
                      <div class="range-slider">
                        <!-- <input type="text" class="js-range-slider" value="" /> -->
                        <Slider @change="priceSliderChanged" v-model="priceRange.value" v-bind="priceRange" :min="priceFilterMin" :max="priceFilterMax" class="slider-red"/>                                
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="accordion-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headingSix">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                      <span>Rating</span>
                    </button>
                  </h2>
                  <div id="collapseSix" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingSix">
                    <div class="accordion-body">
                      <ul class="category-list custom-padding">
                        <li>
                          <div class="form-check ps-0 m-0 category-list-box">
                            <input v-model="selectedRatingId" value="5" class="checkbox_animated" type="checkbox" />
                            <div class="form-check-label rating">
                              <star-rating :max="5" :current="5" />
                              <span class="text-content">(5 Star)</span>
                            </div>
                          </div>
                        </li>

                        <li>
                          <div class="form-check ps-0 m-0 category-list-box">
                            <input v-model="selectedRatingId" value="4" class="checkbox_animated" type="checkbox" />
                            <div class="form-check-label rating">
                              <star-rating :max="5" :current="4" />
                              <span class="text-content">(4 Star)</span>
                            </div>
                          </div>
                        </li>

                        <li>
                          <div class="form-check ps-0 m-0 category-list-box">
                            <input v-model="selectedRatingId" value="3" class="checkbox_animated" type="checkbox" />
                            <div class="form-check-label rating">
                              <star-rating :max="5" :current="3" />
                              <span class="text-content">(3 Star)</span>
                            </div>
                          </div>
                        </li>

                        <li>
                          <div class="form-check ps-0 m-0 category-list-box">
                            <input v-model="selectedRatingId" value="2" class="checkbox_animated" type="checkbox" />
                            <div class="form-check-label rating">
                              <star-rating :max="5" :current="2" />
                              <span class="text-content">(2 Star)</span>
                            </div>
                          </div>
                        </li>

                        <li>
                          <div class="form-check ps-0 m-0 category-list-box">
                            <input v-model="selectedRatingId" value="1" class="checkbox_animated" type="checkbox" />
                            <div class="form-check-label rating">
                              <star-rating :max="5" :current="1" />
                              <span class="text-content">(1 Star)</span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> -->

                <!-- @click="ratingFilter(1)"<div class="accordion-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headingFour">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                      <span>Discount</span>
                    </button>
                  </h2>
                  <div id="collapseFour" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingFour">
                    <div class="accordion-body">
                      <ul class="category-list custom-padding">
                        <li>
                          <div class="form-check ps-0 m-0 category-list-box">
                            <input class="checkbox_animated" type="checkbox" id="flexCheckDefault" />
                            <label class="form-check-label" for="flexCheckDefault">
                              <span class="name">upto 5%</span>
                              <span class="number">(06)</span>
                            </label>
                          </div>
                        </li>

                        <li>
                          <div class="form-check ps-0 m-0 category-list-box">
                            <input class="checkbox_animated" type="checkbox" id="flexCheckDefault1" />
                            <label class="form-check-label" for="flexCheckDefault1">
                              <span class="name">5% - 10%</span>
                              <span class="number">(08)</span>
                            </label>
                          </div>
                        </li>

                        <li>
                          <div class="form-check ps-0 m-0 category-list-box">
                            <input class="checkbox_animated" type="checkbox" id="flexCheckDefault2" />
                            <label class="form-check-label" for="flexCheckDefault2">
                              <span class="name">10% - 15%</span>
                              <span class="number">(10)</span>
                            </label>
                          </div>
                        </li>

                        <li>
                          <div class="form-check ps-0 m-0 category-list-box">
                            <input class="checkbox_animated" type="checkbox" id="flexCheckDefault3" />
                            <label class="form-check-label" for="flexCheckDefault3">
                              <span class="name">15% - 25%</span>
                              <span class="number">(14)</span>
                            </label>
                          </div>
                        </li>

                        <li>
                          <div class="form-check ps-0 m-0 category-list-box">
                            <input class="checkbox_animated" type="checkbox" id="flexCheckDefault4" />
                            <label class="form-check-label" for="flexCheckDefault4">
                              <span class="name">More than 25%</span>
                              <span class="number">(13)</span>
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> -->

                <div class="accordion-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headingFive">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                      <span>Pack Size</span>
                    </button>
                  </h2>
                  <div id="collapseFive" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingFive">
                    <div class="accordion-body">
                      <ul class="category-list custom-padding custom-height">
                        <li v-for="(packagSize, index) in packagSizeList" :key="index">
                          <div class="form-check ps-0 m-0 category-list-box">
                            <input v-model="selectedPackageId" :value="packagSize.id" class="checkbox_animated" type="checkbox" id="flexCheckDefault5" />
                            <label class="form-check-label" for="flexCheckDefault5">
                              <span class="name">{{ packagSize.unit_value }} {{ packagSize.unit }}</span>
                              <!-- <span class="number">(05)</span> -->
                            </label>
                          </div>
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-custome-9 wow fadeInUp">
          <div class="show-button">
            <div class="filter-button-group mt-0">
              <div class="filter-button d-inline-block d-lg-none">
                <a><i class="fa-solid fa-filter"></i> Filter Menu</a>
              </div>
            </div>

            <div class="top-filter-menu">
              <div class="category-dropdown">
                <h5 class="text-content">Sort By :</h5>
                
                <div class="dropdown">
                  <button class="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"><span>{{selectedSortByName}}</span> <i class="fa-solid fa-angle-down"></i></button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li v-for="(sortingBy, index) in sortingByList" :key="index">
                      <a class="dropdown-item" @click="sortDropDown(sortingBy.id,sortingBy.name)" href="javascript:void(0)">{{ sortingBy.name }}</a>
                    </li>
                    <!-- <li>
                      <a class="dropdown-item" id="high" href="javascript:void(0)">High - Low Price</a>
                    </li>
                    <li>
                      <a class="dropdown-item" id="rating" href="javascript:void(0)">Average Rating</a>
                    </li>
                    <li>
                      <a class="dropdown-item" id="aToz" href="javascript:void(0)">A - Z Order</a>
                    </li>
                    <li>
                      <a class="dropdown-item" id="zToa" href="javascript:void(0)">Z - A Order</a>
                    </li> -->
                  </ul>
                </div>
              </div>

              <div class="grid-option d-none d-md-block">
                <ul>
                  <li class="three-grid" :class="{ active: gridActiveEanble == 1 }" @click="gridActive(1)">
                    <a href="javascript:void(0)">
                      <img src="../../../assets/svg/grid-3.svg" class="blur-up lazyload" alt="" />
                    </a>
                  </li>
                  <li class="grid-btn d-xxl-inline-block" :class="{ active: gridActiveEanble == 2 }" @click="gridActive(2)">
                    <a href="javascript:void(0)">
                      <img src="../../../assets/svg/grid-4.svg" class="blur-up lazyload d-lg-inline-block d-none" alt="" />
                      <img src="../../../assets/svg/grid.svg" class="blur-up lazyload img-fluid d-lg-none d-inline-block" alt="" />
                    </a>
                  </li>
                  <li class="list-btn" :class="{ active: gridActiveEanble == 3 }" @click="gridActive(3)">
                    <a href="javascript:void(0)">
                      <img src="../../../assets/svg/list.svg" class="blur-up lazyload" alt="" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <!-- <div class="row g-sm-4 g-3 row-cols-xxl-4 row-cols-xl-3 row-cols-lg-2 row-cols-md-3 row-cols-2 product-list-section"> -->
            <div class="row g-sm-4 g-3 product-list-section row-cols-xl-3 row-cols-lg-2 row-cols-md-3 row-cols-2">
            <div v-for="(productListData, index) in productsListData" :key="index" >
              <div class="product-box-3 h-100 wow fadeInUp">
                <div class="product-header">
                  <div class="product-image">
                    <router-link :to="{ name: 'ProductDetails',params:{productSlug: productListData.slug} }">
                      <img :src="productListData.images[0].image_url" class="img-fluid blur-up lazyload" alt="" />
                    </router-link>

                    <ul class="product-option">
                      <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                        <a href="javascript:void(0)"  @click.prevent="viewProduct(productListData)">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye">
                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                            <circle cx="12" cy="12" r="3"></circle>
                          </svg>
                        </a>
                      </li>

                      <li data-bs-toggle="tooltip" data-bs-placement="top" title="Wishlist">
                        <a href="javascript:void(0)" @click="addToWish(productListData.id,productListData.variants[0].id)" class="notifi-wishlist">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-heart"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="product-footer">
                  <div class="product-detail">
                    <span class="span-name">{{ productListData.category.title }}</span>
                    <router-link :to="{ name: 'ProductDetails',params:{productSlug: productListData.slug} }">
                      <h5 class="name">{{ productListData.name }}</h5>
                    </router-link>
                    <p class="text-content mt-1 mb-2 product-content">{{ productListData.variants[0].description }}</p>
                    <div class="mt-2 rating">
                      <star-rating :max="5" :current="productListData.rating" />
                      <span>({{ productListData.reviews.length }})</span>
                    </div>
                    <!-- <h6 class="unit">{{ productListData.variants[0].unit_value }} {{ productListData.variants[0].unit }}</h6> -->
                    <h5 class="price"><span class="theme-color">₹{{ productListData.variants[0].sale_price }}</span> <del>₹{{ productListData.variants[0].retail_price }}</del></h5>
                  </div>
                </div>
              </div>
            </div>
            <infinite-loading @infinite="getProductList" ref="infiniteLoading" :identifier="infiniteId" spinner="spinner"></infinite-loading>
          </div>
          
        </div>
      </div>
    </div>
    <!-- Quick View Modal Box Start -->
    <Teleport to="body">
        <div class="modal theme-modal view-modal" id="viewProduct" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-xl modal-fullscreen-sm-down">
            <div class="modal-content">
                <div class="modal-header p-0">
                <button type="button" class="btn-close" @click="closeModel">
                    <i class="fa-solid fa-xmark"></i>
                </button>
                </div>
                <div class="modal-body">
                <div class="row g-sm-4 g-2">
                    <div class="col-lg-6">
                    <div class="slider-image">
                        <img :src="productViewimage" class="img-fluid blur-up lazyload" alt="" />
                    </div>
                    </div>

                    <div class="col-lg-6">
                    <div class="right-sidebar-modal">
                        <h4 class="title-name">{{ productViewName }}</h4>
                        <h4 class="price1">₹{{ productViewSalePrice }} <del>₹{{ productViewRetailPrice }}</del></h4>
                        <div class="product-rating">
                        <ul class="rating">
                            <star-rating :max="5" :current="productViewRating" />
                        </ul>
                        <span class="ms-2">{{ productViewReviewCount }} Reviews</span>
                        <!-- <span class="ms-2 text-danger">6 sold in last 16 hours</span> -->
                        </div>

                        <div class="product-detail">
                        <h4>Product Details :</h4>
                        <p>{{ productViewDetail }}</p>
                        </div>

                        <ul class="brand-list">
                            <li>
                                <div class="brand-box">
                                <h5>Weight:</h5>
                                <h6>{{ productViewWeight }}</h6>
                                </div>
                            </li>
                        </ul>

                        <div class="modal-button" v-if="productViewSlug">
                            <router-link :to="{ name: 'ProductDetails',params:{productSlug: productViewSlug}}" class="btn theme-bg-color view-button icon text-white fw-bold btn-md">View More Details</router-link>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
    </Teleport>
    <!-- Quick View Modal Box End --> 
  </section>
  <!-- Shop Section End -->
</template>

<script>
import starRating from "../../../components/RatingComponent";
import BreadscrumbSection from '@/components/forntEnd/BreadscrumbSection.vue';
import HeaderComponent from '@/components/template/front/HeaderComponent.vue';
import { mapActions } from "vuex";
import axiosInstance from "../../../helper/axiosInstance";
import { toast } from 'vue3-toastify';
import Slider from '@vueform/slider'
import '@vueform/slider/themes/default.css'
export default {
  components: { starRating, BreadscrumbSection, Slider, HeaderComponent },
  data() {
    return {
      priceRange: {
        value: [0,50000]
      },
      gridActiveEanble: 1,
      categoryListData: {}, 
      packagSizeList: {}, 
      sortingByList: {}, 
      priceFilterMin: 0, 
      priceFilterMax: 0, 
      productsListData: [],
      selectedCaegoryId: [],
      selectedPackageId: [],
      selectedRatingId: [],
      selectedSortById: "",
      selectedMinPrice: "",
      selectedMaxPrice: "",
      selectedSortByName: "Latest Products",
      page: 1,
      infiniteId: +new Date(),
      checkTokenLoggedIn: false,

      productViewimage: '',
      productViewName: '',
      productViewSalePrice: 0,
      productViewRetailPrice: 0,
      productViewReviewCount: 0,
      productViewDetail: '',
      productViewRating: 0,
      productViewWeight: '',
      productViewSlug: ''

    };
  },
  watch: {
    selectedCaegoryId () { 
      let vueInstance = this;
      vueInstance.page = 1;
      vueInstance.productsListData= [];
      vueInstance.getProductList();
    },
    // selectedRatingId () { 
    //   let vueInstance = this;
    //   vueInstance.page = 1;
    //   vueInstance.getProductList();
    // },
    selectedPackageId () { 
      let vueInstance = this;
      vueInstance.page = 1;
      vueInstance.productsListData= [];
      vueInstance.getProductList();
    },
    // selectedSortById () { 
    //   let vueInstance = this;
    //   vueInstance.page = 1;
    //   vueInstance.productsListData= [];
    //   vueInstance.getProductList();
    // },
  },
  created() {
      this.getcategoryList();
      this.getFiltersDataList();
  },
  
  methods: {
    priceSliderChanged(e){ 
      let vueInstance = this;
      vueInstance.selectedMinPrice = e[0];
      vueInstance.selectedMaxPrice = e[1];
      vueInstance.page = 1;
      vueInstance.productsListData= [];
      vueInstance.getProductList();
    },
    sortDropDown(id,name){ 
      let vueInstance = this;
      vueInstance.selectedSortById = id;
      vueInstance.selectedSortByName = name;
      vueInstance.productsListData= [];
      vueInstance.page = 1;
      vueInstance.getProductList();
    },
    // categoryFilter(e,caegoryId){
    //   let vueInstance = this;
    //   vueInstance.page = 1;
    //   vueInstance.productsListData= [];
    //   vueInstance.selectedCaegoryId.push(caegoryId);
    //   // console.log(vueInstance.selectedCaegoryId);
    //   // vueInstance.getProductList();
    // },

    // packageSizeFilter(packageId){
    //   let vueInstance = this;
    //   vueInstance.page = 1;
    //   // vueInstance.productsListData= [];
    //   vueInstance.selectedPackageId.push(packageId);
    //   console.log(vueInstance.selectedCaegoryId);
    //   // this.getDealProductList();
    // },

    // ratingFilter(packageId){
    //   let vueInstance = this;
    //   vueInstance.page = 1;
    //   // vueInstance.productsListData= [];
    //   vueInstance.selectedPackageId.push(packageId);
    //   console.log(vueInstance.selectedCaegoryId);
    //   // this.getDealProductList();
    // },

    async getProductList($state) {
      let vueInstance = this;
      var paramsUrl = "";
      if(vueInstance.selectedCaegoryId.length > 0){        
        paramsUrl += `&categories=${vueInstance.selectedCaegoryId}`
      }
      // if(vueInstance.selectedRatingId.length > 0){
      //   vueInstance.productsListData= [];
      //   paramsUrl += `&rating=${vueInstance.selectedRatingId}`
      // }
      if(vueInstance.selectedSortById){
        paramsUrl += `&sortOptions=${vueInstance.selectedSortById}`
      }
      if(vueInstance.selectedPackageId.length > 0){
        paramsUrl += `&packages=${vueInstance.selectedPackageId}`
      }
      if(vueInstance.selectedMinPrice && vueInstance.selectedMaxPrice){ 
        paramsUrl += `&minPrice=${vueInstance.selectedMinPrice}`;
        paramsUrl += `&maxPrice=${vueInstance.selectedMaxPrice}`;
      }
      
      axiosInstance.get(`categories/${vueInstance.$route.params.categorySlug}/products?page=${vueInstance.page}${paramsUrl}`)
        .then((res) => {
          if (res.error == true) {
            return false;
          }
          if (res.data.response.data.length > 0) {
            vueInstance.page += 1;
            vueInstance.productsListData.push(...res.data.response.data);
            $state.loaded();
          } else {
            $state.complete();
          } 
        });
    },

    async addToWish(productId,variantId){
      let vueInstance = this;
      if(!vueInstance.checkTokenLoggedIn){
          toast.error("You are not logged in. Please login to add the product to your the card");
          setTimeout(function(){
              vueInstance.$router.push({ name: "Login" });
          }, 5000);
          return false
      }            
      await vueInstance.actionAddToWish({
          variant_id: variantId,
          product_id: productId,
          wish_type: "ADD",
      }).then(results => {
          if (results.error == true) {
              toast.error(results.message);
              return false
          }
          toast.success(results.message);
          return false;
      }).catch((err) => {
          toast.error(err.message);
          return false;
      });
    },

    async getcategoryList() {
      let vueInstance = this;
      vueInstance.checkTokenLoggedIn= this.$store.state.auth.authData.token;
      await vueInstance.actionGetCategoriesProducts().then((res) => {
          if (res.error == true) {
              return false;
          }
          vueInstance.categoryListData = res.response;
          vueInstance.checkTokenLoggedIn= vueInstance.$store.state.auth.authData.token;
      })
    },

    async getFiltersDataList() {
      let vueInstance = this;
      await vueInstance.actionGetFiltersDataList().then((res) => {
          if (res.error == true) {
              return false;
          }
          vueInstance.packagSizeList = res.response.packages;
          vueInstance.sortingByList = res.response.sortOptions;
          res.response.priceRang.map(function(priceData) {
            if(priceData.min_price){
              vueInstance.priceFilterMin = parseInt(priceData.min_price)
              // vueInstance.priceRange.value.push(parseInt(priceData.min_price))
            }else if(priceData.max_price) {
              vueInstance.priceFilterMax = parseInt(priceData.max_price)
              // vueInstance.priceRange.value.push(parseInt(priceData.max_price))
            }
          });
          vueInstance.checkTokenLoggedIn= vueInstance.$store.state.auth.authData.token;
      })
    },

    viewProduct(productViewDetail){ 
        let vueInstance = this;
        vueInstance.productViewimage = productViewDetail.images[0].image_url;
        vueInstance.productViewName = productViewDetail.name;
        vueInstance.productViewSlug = productViewDetail.slug;
        vueInstance.productViewSalePrice = productViewDetail.variants[0].sale_price;
        vueInstance.productViewRetailPrice = productViewDetail.variants[0].retail_price;
        vueInstance.productViewReviewCount = productViewDetail.reviews.length
        vueInstance.productViewDetail = productViewDetail.variants[0].description;
        vueInstance.productViewWeight = productViewDetail.variants[0].unit_value + productViewDetail.variants[0].unit;
        vueInstance.productViewRating = productViewDetail.rating;
        $('#viewProduct').show()
    },

    closeModel(){ 
      $('#viewProduct').hide()
    },

    gridActive(el) { 
      this.gridActiveEanble = false;
      if (el == 1) {
        $(".product-list-section").removeClass("row-cols-xxl-5 row-cols-xxl-4 row-cols-xl-3 row-cols-lg-2 row-cols-md-3 row-cols-2 list-style").addClass("row-cols-xl-3 row-cols-lg-2 row-cols-md-3 row-cols-2");
      } 
      else if (el == 2) {
        $(".product-list-section").removeClass("list-style");
        $(".product-list-section").removeClass("row-cols-xxl-5 row-cols-xxl-4 row-cols-xl-3 row-cols-lg-2 row-cols-md-3 row-cols-2 list-style").addClass("row-cols-xxl-4 row-cols-xl-4 row-cols-lg-2 row-cols-md-3 row-cols-2");
      } 
      else {
        $(".product-list-section").addClass("list-style");
      }
      this.gridActiveEanble = el;
    },
    ...mapActions("customer", {
      actionGetCategoriesProducts: "getCategoryProductsList",
    }),
    ...mapActions("customer", {
      actionAddToWish: "addToWish",
    }),
    ...mapActions("customer", {
      actionGetFiltersDataList: "getFiltersDataList",
    }),
  },
};
</script>
<style scoped>
  /* .rating {
    display: inline-flex;
    align-items: center;
    text-align: center;
    margin-bottom: 10px;
  } */
  .unit, .price {
    text-align: center !important;
  }
  .checkbox_animated:before {
    content: "";
    position: absolute;
    width: 12px;
    height: 6px;
    /* -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75); */
    transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75), -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    -webkit-transform: rotate(-45deg) scale(0, 0);
    transform: rotate(-45deg) scale(0, 0);
    left: 4px;
    top: 4px;
    z-index: 1;
    border: 2px solid #7F2622;
    border-top-style: none;
    border-right-style: none;
  }
  .slider-red {
    --slider-connect-bg: #7F2622;
    --slider-tooltip-bg: #7F2622;
    --slider-handle-ring-color: #7F2622;
  }
  .grid-option:active{
    background-color: #7F2622;
  }
  .shop-section .show-button .top-filter-menu .grid-option ul li.active {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background: linear-gradient(149.8deg, #7F2622 17.21%, #7F2622 81.21%) !important;
}
</style>