<template>
    <router-view :key="$route.fullPath"/>
    <footer-component/>
</template>
<script>
// import './assets/css/vendors/bootstrap.css'; 
// import './assets/css/vendors/font-awesome.css'; 
// import './assets/css/vendors/feather-icon.css'; 
// import './assets/css/vendors/slick/slick.css'; 
 

import FrontLayOutComponent from './components/layout/FrontLayOutComponent.vue';
import FooterComponent from './components/template/front/FooterComponent.vue';

export default {
    setup() {
    },
    components: { FrontLayOutComponent, FooterComponent }
}
</script>
