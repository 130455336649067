import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import jwt_decode from "jwt-decode";
import store from '../store/index'
import { Role } from '../helper/role'
import Home from '../views/Client/FrontEnd/Home'
import ShopByCategory from '../views/Client/FrontEnd/ShopByCategory'
import ProductDetails from '../views/Client/FrontEnd/ProductDetails'
import AddToCart from '../views/Client/BackEnd/CheckOut/AddToCart'
import CheckOutPorcess from '../views/Client/BackEnd/CheckOut/CheckOutProcess'
import OrderSuccess from '../views/Client/BackEnd/CheckOut/OrderSucces'
import OrderFailed from '../views/Client/BackEnd/CheckOut/OrderFailed'
import OrderTracking from '../views/Client/BackEnd/CheckOut/OrderTracking'



const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Kingdoms of Grains',
    },
  },
  {
    path: '/shop-by-category/:categorySlug?',
    name: 'ShopByCategory',
    component: ShopByCategory,
    meta: {
      title: 'Shop By Category',
    },
  },
  {
    path: '/product-detail/:productSlug',
    name: 'ProductDetails',
    component: ProductDetails,
    meta: {
      title: 'product-details',
    },
  },
  {
    path: '/my-cart',
    name: 'AddToCart',
    component: AddToCart,
    meta: {
      title: 'Add To Cart',
      permission: Role.Customer,
      requiresAuth: true,
      customerAuth: true,
    },
  },
  {
    path: '/check-out',
    name: 'CheckOutPorcess',
    component: CheckOutPorcess,
    meta: {
      title: 'Check Out',
      permission: Role.Customer,
      requiresAuth: true,
      customerAuth: true,
    },
  },
  {
    path: '/order-success',
    name: 'OrderSuccess',
    component: OrderSuccess,
    meta: {
      title: 'Order Success',
      permission: Role.Customer,
      requiresAuth: true,
      customerAuth: true,
    },
  },
  {
    path: '/order-failed',
    name: 'OrderFailed',
    component: OrderFailed,
    meta: {
      title: 'Order Failed',
      permission: Role.Customer,
      requiresAuth: true,
      customerAuth: true,
    },
  },
  {
    path: '/order-tracking/:orderDisplayId?',
    name: 'OrderTracking',
    component: OrderTracking,
    meta: {
      title: 'Order Tracking',
      permission: Role.Customer,
      requiresAuth: true,
      customerAuth: true,
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Client/BackEnd/Dashboard/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
      permission: Role.Customer,
      requiresAuth: true,
      customerAuth: true,
    },
  },
  {
    path: '/my-order-list',
    name: 'OrderList',
    component: () => import(/* webpackChunkName: "orderList" */ '../views/Client/BackEnd/Dashboard/OrderList.vue'),
    meta: {
      title: 'Order List',
      permission: Role.Customer,
      requiresAuth: true,
      customerAuth: true,
    },
  },
  {
    path: '/my-address',
    name: 'MyAddress',
    component: () => import(/* webpackChunkName: "addresses" */ '../views/Client/BackEnd/Dashboard/Addresses.vue'),
    meta: {
      title: 'My Addresses',
      permission: Role.Customer,
      requiresAuth: true,
      customerAuth: true,
    },
  },
  {
    path: '/refer-code',
    name: 'ReferCode',
    component: () => import(/* webpackChunkName: "addresses" */ '../views/Client/BackEnd/Dashboard/ReferCode.vue'),
    meta: {
      title: 'Refer Code',
      permission: Role.Customer,
      requiresAuth: true,
      customerAuth: true,
    },
  },
  {
    path: '/my-wish-list',
    name: 'MyWishList',
    component: () => import(/* webpackChunkName: "addresses" */ '../views/Client/BackEnd/Dashboard/WishList.vue'),
    meta: {
      title: 'Wish List',
      permission: Role.Customer,
      requiresAuth: true,
      customerAuth: true,
    },
  },
  {
    path: '/my-profile',
    name: 'MyProfile',
    component: () => import(/* webpackChunkName: "addresses" */ '../views/Client/BackEnd/Dashboard/Profile.vue'),
    meta: {
      title: 'My Profile',
      permission: Role.Customer,
      requiresAuth: true,
      customerAuth: true,
    },
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/Client/BackEnd/Dashboard/Privacy.vue'),
    meta: {
      title: 'Privacy',
      permission: Role.Customer,
      requiresAuth: true,
      customerAuth: true,
    },
  },
  {
    path: '/order-details/:orderDisplayId',
    name: 'OrderDetails',
    component: () => import(/* webpackChunkName: "orderDetails" */ '../views/Client/BackEnd/Dashboard/OrderDetails.vue'),
    meta: {
      title: 'Order Details',
      permission: Role.Customer,
      requiresAuth: true,
      customerAuth: true,
    },
  },
  {
    path: '/wish-list',
    name: 'WishList',
    component: () => import(/* webpackChunkName: "wishList" */ '../views/Client/BackEnd/WishList.vue'),
    meta: {
      title: 'WishList',
      permission: Role.Customer,
      requiresAuth: true,
      customerAuth: true,
    },
  },
  {
    path: '/about',
    name: 'AboutUs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Client/FrontEnd/AboutUs.vue'),
    meta: {
      title: 'About Us',
    },
  },
  {
    path: '/term-condition',
    name: 'TermsCondition',
    component: () => import(/* webpackChunkName: "about" */ '../views/Client/FrontEnd/TermsCondition.vue'),
    meta: {
      title: 'Terms Condition',
    },
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackChunkName: "about" */ '../views/Client/FrontEnd/PrivacyPolicy.vue'),
    meta: {
      title: 'Privacy Policy',
    },
  },
  {
    path: '/contact',
    name: 'ContactUs',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Client/FrontEnd/ContactUs.vue'),
    meta: {
      title: 'Contact Us',
    },
  },
  {
    path: '/faq',
    name: 'Faq',
    component: () => import(/* webpackChunkName: "faq" */ '../views/Client/FrontEnd/Faq.vue'),
    meta: {
      title: 'Faq',
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Client/FrontEnd/Login.vue'),
    meta: {
      title: 'Login',
      requiresUnauth: true,
    },
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: () => import(/* webpackChunkName: "signUp" */ '../views/Client/FrontEnd/SignUp.vue'),
    meta: {
      title: 'SignUp',
      requiresUnauth: true,
    },
  },
  {
    path: '/otp',
    name: 'Otp',
    component: () => import(/* webpackChunkName: "otp" */ '../views/Client/FrontEnd/Otp.vue'),
    meta: {
      title: 'Otp',
      requiresUnauth: true,
    },
  },
  {
    path: '/sign-verification',
    name: 'SignUpOtp',
    component: () => import(/* webpackChunkName: "otp" */ '../views/Client/FrontEnd/SignUpOtp.vue'),
    meta: {
      title: 'Sign Up Otp',
      requiresUnauth: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('../views/Client/FrontEnd/ForgotPassword.vue'),
    meta: {
      title: 'Forgot Password',
      requiresUnauth: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: () => import('../views/Client/FrontEnd/PageNotFound.vue'),
    meta: {
      title: 'Page Not Found',
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL, 
  routes,
})

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title;
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const customerAuth = to.matched.some(record => record.meta.customerAuth);
  const requiresUnauth = to.matched.some(record => record.meta.requiresUnauth);

  var isAuthenticated = true;
  const authData = await store.getters['auth/getAuthData']
  let loginRole = authData.loggedInRole;
  if(!authData.token){
    const getLocalToken = localStorage.getItem('token');
    if(getLocalToken){
      const roleInToken = await jwt_decode(getLocalToken)
      loginRole = roleInToken.role;
      const data = {
        token : getLocalToken,
        role : loginRole,
      }
      await store.commit('auth/setAuthData' , data);
      
    }else{
       isAuthenticated = false;
    }
  }
  if(isAuthenticated && requiresAuth) { 
    if(customerAuth){
      if(loginRole == Role.Customer) return next();
      else  return next({name: 'Login' });
    }
    else{
      return next({name: 'Login' });
    }
  }else if (requiresUnauth &&  isAuthenticated){ 
    return next({name: 'OrderList' });
  }else if(!isAuthenticated && requiresAuth){
    return next({name: 'Login'});
  }else{
    return next();
  }
});

router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start()
  }
  next()
})

router.afterEach((to, from) => {
  NProgress.done()
})

export default router
