<template>
  <header-component/>
  <!-- Breadcrumb Section Start -->
  <breadscrumb-section :pageName="productData.name"/>
  <!-- Breadcrumb Section End -->

  <!-- Product Left Sidebar Start -->
  <section class="product-section">
    <div class="container-fluid-lg">
      <div class="row">
        <div class="col-xxl-9 col-xl-8 col-lg-7 wow fadeInUp">
          <div class="row g-4">
            <div class="col-xl-6 wow fadeInUp">
              <div class="product-left-box">
                <div class="row g-2">
                  <div class="col-12">
                    <swiper class="product-main-1"
                      :autoplay="{
                        delay: 5000,
                        disableOnInteraction: true,
                      }"
                      :loop="true"
                      :loopedSlides="4"
                      :slidesPerView="1"
                      :spaceBetween="20"
                      :modules="modules"
                      :lazy="true"
                      :thumbs="{ swiper: thumbsSwiper }"
                    >
                        <swiper-slide v-for="(image,index) in images" :key="index">
                            <div class="slider-image">
                            <img :src="image.image_url" id="img-1" :data-zoom-image="image.image_url" class="img-fluid image_zoom_cls-0 blur-up lazyload" alt="" />
                            </div>
                        </swiper-slide>
                    </swiper>
                  </div>

                  <div class="col-12">
                    <swiper class="bottom-slider-image left-slider" @swiper="setThumbsSwiper" :touchRatio="0.2" :spaceBetween="10" :slidesPerView="4" :freeMode="true" :watchSlidesVisibility="true" :watchSlidesProgress="true" :modules="modules" :lazy="true" :loopedSlides="4">
                      <swiper-slide v-for="(image,jIndex) in images" :key="jIndex">
                        <div class="sidebar-image">
                          <img :src="image.image_url" class="img-fluid blur-up lazyload" alt="" />
                        </div>
                      </swiper-slide>
                    </swiper>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="right-box-contain">
                <!-- <h6 class="offer-top">30% Off</h6> -->
                <h2 class="name">{{productData.name}}</h2>
                <div class="price-rating">
                  <h3 class="theme-color price">₹{{attributeData.variantSalePrice}} <del class="text-content">₹{{attributeData.variantRetailPrice}}</del> <span class="offer theme-color">
                    <!-- (10% off) -->

                  </span></h3>
                  <div class="product-rating custom-rate">
                    <star-rating :max="5" :current="productData.rating" />
                    <span class="review">{{productData.total_review_count}} Customer Review</span>
                  </div>
                </div>

                <!-- <div class="procuct-contain">
                  <p>{{attributeData.variantDescription}}</p>
                </div> -->

                <div class="product-packege" v-if="attributeData.variants">
                  <div class="product-title">
                    <h4>Weight</h4>
                  </div>
                  <ul class="select-packege">
                    <li v-for="(option,lIndex) in attributeData.variantsName" :key="lIndex">
                      <a href="javascript:void(0)" @click.prevent="setActiveVariant(option)" :class="option.id == variantId? 'active':''">{{option.unit_value}} {{option.unit}}</a>
                    </li>                    
                  </ul>
                </div>
                <div class="product-packege" v-if="attributeData.grainAttributes">
                  <div class="product-title">
                    <h4>Pisai</h4>
                  </div>
                  <ul class="select-packege">
                    <li v-for="(option,lIndex) in attributeData.grainAttributesName" :key="lIndex">
                      <a href="javascript:void(0)" @click.prevent="setActiveGrain(option)" :class="option.id == grainId? 'active':''">{{option.value}} </a>
                    </li>                    
                  </ul>
                </div>

                <div class="note-box product-packege">
                  <div class="cart_qty qty-box product-qty">
                    <div class="input-group">
                      <button type="button" @click.prevent="changeQuantity('decrease')" class="qty-right-plus" data-type="plus" data-field="">
                        <i class="fa fa-minus" aria-hidden="true"></i>
                      </button>
                      <input v-model="quantity" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" readonly class="form-control input-number qty-input" type="text" />
                      <button @click.prevent="changeQuantity('increase')" type="button" class="qty-left-minus" data-type="minus" data-field="">
                        <i class="fa  fa-plus" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div> 

                  <button @click="addToCart" class="btn btn-md bg-dark cart-button text-white w-100">
                    <i v-if="spinnerShow" class="fa fa-spinner fa-spin"></i>Add To Cart
                  </button>
                </div>
                <div class="buy-box">
                  <div v-if="$store.state.customer.addedProducts && totalCartCount > 0" class="align-items-end justify-content-end mb-2" style="margin-left: 158px;">
                    <router-link :to="{ name: 'CheckOutPorcess' }" class="btn btn-md cart-button theme-bg-color text-white w-100">Checkout</router-link>
                  </div>
                  <p><h5>WE GRIND ONLY AFTER YOU PLACE YOUR ORDER</h5></p>
                </div>

                <div class="buy-box">
                  <span style="color:#7F2622">We Have A "No Questions Asked Return Policy" If You Dont Like Our Products With Guaranteed FULL REFUND.</span>
                </div>

                <!-- <div class="paymnet-option">
                  <div class="product-title">
                    <h4>Guaranteed Safe Checkout</h4>
                  </div>
                  <ul>
                    <li>
                      <a href="javascript:void(0)">
                        <img src="../../../assets/images/product/payment/1.svg" class="blur-up lazyload" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">
                        <img src="../../../assets/images/product/payment/2.svg" class="blur-up lazyload" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">
                        <img src="../../../assets/images/product/payment/3.svg" class="blur-up lazyload" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">
                        <img src="../../../assets/images/product/payment/4.svg" class="blur-up lazyload" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">
                        <img src="../../../assets/images/product/payment/5.svg" class="blur-up lazyload" alt="" />
                      </a>
                    </li> 
                  </ul>
                </div> -->
              </div>
            </div>

            <div class="col-12">
              <div class="product-section-box">
                <ul class="nav nav-tabs custom-nav" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="description-tab" data-bs-toggle="tab" data-bs-target="#description" type="button" role="tab" aria-controls="description" aria-selected="true">Description</button>
                  </li>

                  <!-- <li class="nav-item" role="presentation">
                    <button class="nav-link" id="info-tab" data-bs-toggle="tab" data-bs-target="#info" type="button" role="tab" aria-controls="info" aria-selected="false">Additional info</button>
                  </li> -->
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="review-tab" data-bs-toggle="tab" data-bs-target="#review" type="button" role="tab" aria-controls="review" aria-selected="false">Review</button>
                  </li>
                </ul>

                <div class="tab-content custom-tab" id="myTabContent">
                  <div class="tab-pane fade show active" id="description" role="tabpanel" aria-labelledby="description-tab">
                    <div class="product-description">
                      <div class="information-box">
                        {{attributeData.variantDescription}}
                      </div>
                    </div>
                  </div>

                  <!-- <div class="tab-pane fade" id="info" role="tabpanel" aria-labelledby="info-tab">
                    <div class="table-responsive">
                      <table class="table info-table">
                        <tbody>
                          <tr>
                            <td>Specialty</td>
                            <td>No Maida</td>
                          </tr>
                          <tr>
                            <td>Ingredient Type</td>
                            <td>Vegetarian</td>
                          </tr>
                          <tr>
                            <td>Brand</td>
                            <td>Aashirvaad</td>
                          </tr>
                          <tr>
                            <td>Form</td>
                            <td>RAW</td>
                          </tr>
                          <tr>
                            <td>Package Information</td>
                            <td>Packaging</td>
                          </tr>
                          <tr>
                            <td>Manufacturer</td>
                            <td>Kingdom of Grains</td>
                          </tr>
                          <tr>
                            <td>Net Quantity</td>
                            <td>5.00 KG</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div> -->

                  <div class="tab-pane fade" id="review" role="tabpanel" aria-labelledby="review-tab">
                    <div class="review-box">
                      <div class="row g-4">
                        <!-- <div class="col-xl-6">
                          <div class="review-title">
                            <h4 class="fw-500">Customer reviews</h4>
                          </div>

                          <div class="d-flex">
                            <div class="product-rating">
                              <ul class="rating">
                                <li>
                                  <i data-feather="star" class="fill"></i>
                                </li>
                                <li>
                                  <i data-feather="star" class="fill"></i>
                                </li>
                                <li>
                                  <i data-feather="star" class="fill"></i>
                                </li>
                                <li>
                                  <i data-feather="star"></i>
                                </li>
                                <li>
                                  <i data-feather="star"></i>
                                </li>
                              </ul>
                            </div>
                            <h6 class="ms-3">4.2 Out Of 5</h6>
                          </div>

                          <div class="rating-box">
                            <ul>
                              <li>
                                <div class="rating-list">
                                  <h5>5 Star</h5>
                                  <div class="progress">
                                    <div class="progress-bar" role="progressbar" style="width: 68%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">68%</div>
                                  </div>
                                </div>
                              </li>

                              <li>
                                <div class="rating-list">
                                  <h5>4 Star</h5>
                                  <div class="progress">
                                    <div class="progress-bar" role="progressbar" style="width: 67%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">67%</div>
                                  </div>
                                </div>
                              </li>

                              <li>
                                <div class="rating-list">
                                  <h5>3 Star</h5>
                                  <div class="progress">
                                    <div class="progress-bar" role="progressbar" style="width: 42%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">42%</div>
                                  </div>
                                </div>
                              </li>

                              <li>
                                <div class="rating-list">
                                  <h5>2 Star</h5>
                                  <div class="progress">
                                    <div class="progress-bar" role="progressbar" style="width: 30%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">30%</div>
                                  </div>
                                </div>
                              </li>

                              <li>
                                <div class="rating-list">
                                  <h5>1 Star</h5>
                                  <div class="progress">
                                    <div class="progress-bar" role="progressbar" style="width: 24%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">24%</div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div> -->

                        <div class="col-12">
                          <!-- <div class="review-title">
                            <h4 class="fw-500">Customers Reviews</h4>
                          </div> -->

                          <div class="review-people">
                            <ul class="review-list">
                              <li v-for="(customerReview,jIndex) in customerReviews" :key="jIndex">
                                <div class="people-box">
                                  <div>
                                    <!-- <div class="people-image">
                                      <img :src="customerReview.photo" class="img-fluid blur-up lazyload" alt="" />
                                    </div> -->
                                  </div>

                                  <div class="people-comment">
                                    <a class="name" href="javascript:void(0)">{{ customerReview.title }}</a>
                                    <div class="date-time">
                                      <h6 class="text-content">{{  $filters.longDateSecCreatedAt(customerReview.created_at) }} </h6>
                                        <!-- {{ $filters.longDateSec(customerReview.created_at) }}</h6> -->

                                      <div class="product-rating">
                                        <ul class="rating">
                                          <star-rating :max="5" :current="customerReview.rate" />
                                        </ul>
                                      </div>
                                    </div>
                                    <div class="reply">
                                      <p>{{ customerReview.comment }}</p>
                                    </div>
                                  </div>
                                </div>
                              </li>

                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xxl-3 col-xl-4 col-lg-5 d-none d-lg-block wow fadeInUp">
          <div class="right-sidebar-box">
            <!-- <div class="vendor-box">
              <div class="verndor-contain">
                <div class="vendor-image">
                  <img src="../../../assets/images/product/vendor.png" class="blur-up lazyload" alt="" />
                </div>

                <div class="vendor-name">
                  <h5 class="fw-500">Kingdom Of Grains.</h5>

                  <div class="product-rating mt-1">
                    <ul class="rating">
                      <li>
                        <i data-feather="star" class="fill"></i>
                      </li>
                      <li>
                        <i data-feather="star" class="fill"></i>
                      </li>
                      <li>
                        <i data-feather="star" class="fill"></i>
                      </li>
                      <li>
                        <i data-feather="star" class="fill"></i>
                      </li>
                      <li>
                        <i data-feather="star"></i>
                      </li>
                    </ul>
                    <span>(36 Reviews)</span>
                  </div>
                </div>
              </div>

              <p class="vendor-detail">Welcome to Kingdom of Grain, your one-stop-shop for fresh flour and other high-quality grains. We are a team of passionate individuals who believe in the power of wholesome, nutritious food.</p>

              <div class="vendor-list">
                <ul>
                  <li>
                    <div class="address-contact">
                      <i data-feather="map-pin"></i>
                      <h5>Address: <span class="text-content">103, Noida Uttar Pradesh</span></h5>
                    </div>
                  </li>

                  <li>
                    <div class="address-contact">
                      <i data-feather="headphones"></i>
                      <h5>Contact Seller: <span class="text-content">(+91) 701 182 2209</span></h5>
                    </div>
                  </li>
                </ul>
              </div>
            </div> -->

            <!-- Trending Product -->
            <div class="pt-25">
              <div class="category-menu">
                <h3>Trending Products</h3>

                <ul class="product-list product-right-sidebar border-0 p-0">
                  <li v-for="(topSelling,jIndex) in topSelling" :key="jIndex">
                    <div class="offer-product">
                      <router-link :to="{ name: 'ProductDetails',params:{productSlug: topSelling.slug}}" class="offer-image">
                        <img :src="topSelling.images[0].image_url" class="img-fluid blur-up lazyload" alt="" />
                      </router-link>

                      <div class="offer-detail">
                        <div>
                          <router-link :to="{ name: 'ProductDetails',params:{productSlug: topSelling.slug}}">
                            <h6 class="name">{{topSelling.name}}</h6>
                          </router-link>
                          <span>{{topSelling.variant.unit_value}} {{topSelling.variant.unit}}</span>
                          <h6 class="price theme-color">₹{{ topSelling.variant.sale_price }} <del>₹{{ topSelling.variant.retail_price }}</del></h6>
                        </div>
                      </div>
                    </div>
                  </li>                  
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Product Left Sidebar End -->

  <!-- Releted Product Section Start -->
  <section class="product-list-section section-b-space">
    <div class="container-fluid-lg">
      <div class="title">
        <h2>Related Products</h2>
        <span class="title-leaf">
          <svg class="icon-width">
            <use xlink:href="../../../assets/svg/leaf.svg#leaf"></use>
          </svg>
        </span>
      </div>
      <div class="row">
        <div class="col-12">
          <!-- <div class="slider-6_1 product-wrapper"> -->
          <swiper
            class="product-wrapper swiper-wrapper mb-5" :navigation="true"
            :breakpoints="swiperBreakpoints"
            :spaceBetween="30"
            :modules="modules"
            :lazy="true"
            :style="{
              '--swiper-pagination-bullet-active': 'saddlebrown',
              '--swiper-pagination-color': 'saddlebrown',
            }"
            :autoplay="{
              delay: 5000,
              disableOnInteraction: false,
            }"
            :pagination="{
              dynamicBullets: true,
              clickable: true,
            }"
          >
            <swiper-slide v-for="(relatedProduct,jIndex) in relatedProducts" :key="jIndex">
              <div class="product-box-3 wow fadeInUp">
                <div class="product-header">
                  <div class="product-image">
                    <router-link :to="{ name: 'ProductDetails',params:{productSlug: relatedProduct.slug}}">
                      <img :src="relatedProduct.images[0].image_url" class="img-fluid blur-up lazyload" alt="" />
                    </router-link>

                    <ul class="product-option">
                      <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                        <a href="javascript:void(0)" @click.prevent="viewProduct(relatedProduct)">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye">
                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                            <circle cx="12" cy="12" r="3"></circle>
                          </svg>
                        </a>
                      </li>

                      <li data-bs-toggle="tooltip" data-bs-placement="top" title="Wishlist">
                        <a href="javascript:void(0)" class="notifi-wishlist" @click="addToWish(relatedProduct.id,relatedProduct.variant.id)">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-heart"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="product-footer">
                  <div class="product-detail">
                    <router-link :to="{ name: 'ProductDetails',params:{productSlug: relatedProduct.slug}}">
                      <h5 class="name">{{ relatedProduct.name }}</h5>
                    </router-link>
                    <div class="product-rating mt-2">
                      <ul class="rating">
                        <star-rating :max="5" :current="relatedProduct.rating" />
                      </ul>
                      <span>({{ relatedProduct.rating }})</span>
                    </div>
                    <!-- <h6 class="unit">500 G</h6> -->
                    <h5 class="price"><span class="theme-color">₹{{ relatedProduct.variant.sale_price }}</span> <del>₹{{ relatedProduct.variant.retail_price }}</del></h5>
                    <!-- <div class="add-to-cart-box bg-white">
                      <button class="btn btn-add-cart addcart-button">
                        Add
                        <span class="add-icon bg-light-gray">
                          <i class="fa-solid fa-plus"></i>
                        </span>
                      </button>
                      <div class="cart_qty qty-box">
                        <div class="input-group bg-white">
                          <button type="button" class="qty-left-minus bg-gray" data-type="minus" data-field="">
                            <i class="fa fa-minus" aria-hidden="true"></i>
                          </button>
                          <input class="form-control input-number qty-input" type="text" name="quantity" value="0" />
                          <button type="button" class="qty-right-plus bg-gray" data-type="plus" data-field="">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                          </button>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </swiper-slide>

          </swiper>
          <!-- </div> -->
        </div>
      </div>
    </div>
  </section>
  <!-- Releted Product Section End -->

  <!-- Quick View Modal Box Start -->
  <Teleport to="body">
        <div class="modal theme-modal view-modal" id="viewProduct" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-xl modal-fullscreen-sm-down">
            <div class="modal-content">
                <div class="modal-header p-0">
                <button type="button" class="btn-close" @click="closeModel">
                    <i class="fa-solid fa-xmark"></i>
                </button>
                </div>
                <div class="modal-body">
                <div class="row g-sm-4 g-2">
                    <div class="col-lg-6">
                    <div class="slider-image">
                        <img :src="productViewimage" class="img-fluid blur-up lazyload" alt="" />
                    </div>
                    </div>

                    <div class="col-lg-6">
                    <div class="right-sidebar-modal">
                        <h4 class="title-name">{{ productViewName }}</h4>
                        <h4 class="price1">₹{{ productViewSalePrice }} <del>₹{{ productViewRetailPrice }}</del></h4>
                        <div class="product-rating">
                        <ul class="rating">
                            <star-rating :max="5" :current="productViewRating" />
                        </ul>
                        <span class="ms-2">{{ productViewReviewCount }} Reviews</span>
                        <!-- <span class="ms-2 text-danger">6 sold in last 16 hours</span> -->
                        </div>

                        <div class="product-detail">
                        <h4>Product Details :</h4>
                        <p>{{ productViewDetail }}</p>
                        </div>

                        <ul class="brand-list">
                            <li>
                                <div class="brand-box">
                                <h5>Weight:</h5>
                                <h6>{{ productViewWeight }}</h6>
                                </div>
                            </li>
                        </ul>

                        <div class="modal-button" v-if="productViewSlug">
                            <router-link :to="{ name: 'ProductDetails',params:{productSlug: productViewSlug}}" class="btn theme-bg-color view-button icon text-white fw-bold btn-md">View More Details</router-link>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
    </Teleport>
    <!-- Quick View Modal Box End --> 
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/pagination";
import 'swiper/css/navigation';
import { mapActions } from "vuex";
import { toast } from 'vue3-toastify';
import starRating from "../../../components/RatingComponent";
// import Swiper core and required modules
import { Autoplay, EffectFade, Thumbs, Pagination, FreeMode, Navigation } from "swiper";
import BreadscrumbSection from '@/components/forntEnd/BreadscrumbSection.vue';
import HeaderComponent from '@/components/template/front/HeaderComponent.vue';
export default {
  components: { Swiper, SwiperSlide, starRating, BreadscrumbSection, HeaderComponent},
  data() {
    return {
      swiperBreakpoints:{
        320: {       
          slidesPerView: 2,
          spaceBetween: 10     
        },
        640: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 5,
        },
      },
      modules: [ Autoplay, EffectFade, Thumbs, Pagination, FreeMode, Navigation],
      thumbsSwiper: null,
      productData:{},
      images:[],
      attributeData:{
        variants: false,
        variantsName: [],
        variantDescription: "",
        variantRetailPrice: "",
        variantSalePrice: "",
        grainAttributes: false,
        grainAttributesName: [],        
      },
      // selectedVariant: "",
      // selectedGrainAttribute: '',
      quantity: 1,
      variantId: "",
      grainId: "",
      customerReviews: [],
      topSelling: [],
      relatedProducts: [],
      error: null,

      productViewimage: '',
      productViewName: '',
      productViewSalePrice: 0,
      productViewRetailPrice: 0,
      productViewReviewCount: 0,
      productViewDetail: '',
      productViewRating: 0,
      productViewWeight: '',
      productViewSlug: ''
    };
  },
  computed: {
    totalCartCount () {
      return this.$store.state.customer.totalCartCount
    }
  },
  created()  {
    if(this.$route.params.productSlug)
        this.getProductData();
    const authData = this.$store.getters['auth/getAuthData']
    if(authData.token) this.isLoggedIn = true;
  },
  methods: {
    async addToWish(productId,variantId){
      let vueInstance = this;
      if(!vueInstance.isLoggedIn){
          toast.error("You are not logged in. Please login to add the product to your the card");
          setTimeout(function(){
              vueInstance.$router.push({ name: "Login" });
          }, 5000);
          return false
      }            
      await vueInstance.actionAddToWish({
          variant_id: variantId,
          product_id: productId,
          wish_type: "ADD",
      }).then(results => {
          if (results.error == true) {
              toast.error(results.message);
              return false
          }
          toast.success(results.message);
          return false;
      }).catch((err) => {
          toast.error(err.message);
          return false;
      });
  },
    viewProduct(productViewDetail){ 
        let vueInstance = this;
        vueInstance.productViewimage = productViewDetail.images[0].image_url;
        vueInstance.productViewName = productViewDetail.name;
        vueInstance.productViewSlug = productViewDetail.slug;
        vueInstance.productViewSalePrice = productViewDetail.variant.sale_price;
        vueInstance.productViewRetailPrice = productViewDetail.variant.retail_price;
        vueInstance.productViewReviewCount = productViewDetail.reviews.length
        vueInstance.productViewDetail = productViewDetail.variant.description;
        vueInstance.productViewWeight = productViewDetail.variant.unit_value + productViewDetail.variant.unit;
        vueInstance.productViewRating = productViewDetail.rating;
        $('#viewProduct').show()
    },
    async getProductData(){
        let vueInstance = this;
        // if(!vueInstance.isLoggedIn){
        //     vueInstance.flashError("You are not logged in. Please login to add the product to your the card", { timeout: 10000 });
        //     vueInstance.$router.push({ name: "login" });
        //     return;
        // }
        await vueInstance.actionProductDetails({id : vueInstance.$route.params.productSlug}).then(results => {
          if(results.error == true){
            toast.error(results.message);
            return;
          }
          vueInstance.productData = results.response;
          vueInstance.images = vueInstance.productData.images;
          vueInstance.customerReviews = vueInstance.productData.reviews;
          vueInstance.topSelling = vueInstance.productData.top_selling;
          vueInstance.relatedProducts = vueInstance.productData.related_products;

          vueInstance.attributeData.variantsName = vueInstance.productData.variants;
         
          vueInstance.attributeData.variantsName.forEach((value, index) => { 
            if(index == 0){
              vueInstance.attributeData.variants = true;
              vueInstance.attributeData.variantDescription = value.description;
              vueInstance.attributeData.variantRetailPrice = value.retail_price;
              vueInstance.attributeData.variantSalePrice = value.sale_price;
              vueInstance.variantId= value.id
            }
          });

          vueInstance.attributeData.grainAttributesName = vueInstance.productData.grain_attributes;
          vueInstance.attributeData.grainAttributesName.forEach((value, index) => {
            if(index == 0){
              vueInstance.attributeData.grainAttributes = true;
              vueInstance.grainId= value.id
            }
        });
      }).catch(err => {
        toast.error("We have facing some problem");
        return false ;
      })
    },
    async addToCart(){
      let vueInstance = this;
      if(!vueInstance.isLoggedIn){
        toast.error("You are not logged in. Please login to add the product to your the card");
        setTimeout(function(){
          localStorage.setItem("productSlug", vueInstance.$route.params.productSlug);
          vueInstance.$router.push({ name: "Login" });
        }, 5000);
        return false
      }
      $(".btn").prop("disabled", true);
      vueInstance.spinnerShow = true;
      let attributes = []
      
      await vueInstance.actionAddToProductCart({
          product_id: vueInstance.productData.id,
          variant_id: vueInstance.variantId,
          grain_attribute_id: vueInstance.grainId,
          quantity: vueInstance.quantity,
      }).then((results) => {
          vueInstance.spinnerShow = false;
          $(".btn").prop("disabled", false);
          $("html, body").animate({ scrollTop:0 }, "slow");
          if (results.error == true) {
            toast.error(results.message);
            return false
          }
          toast.success(results.message);
          return false;
      }).catch((err) => {
          $("html, body").animate({ scrollTop:0 }, "slow");
          toast.error(err.message);
          vueInstance.spinnerShow = false;
          $(".btn").prop("disabled", false);
          return false;
      });
    },
    closeModel(){ 
      $('#viewProduct').hide()
    },
    async changeQuantity(actionType){
        var vueInstance = this;
        var changeQuantity;
        if(actionType ==="increase"){
            changeQuantity = vueInstance.quantity + 1
            // if(changeQuantity > 5){
            //     return false;
            // }
            vueInstance.quantity +=1
        }else{
            changeQuantity = vueInstance.quantity - 1
            if(changeQuantity < 1){
                return false;
            }
            vueInstance.quantity -=1
        }
        return true
    },

    setActiveVariant(optionData) {
      let vueInstance = this;
      vueInstance.attributeData.variantRetailPrice = optionData.retail_price;
      vueInstance.attributeData.variantSalePrice = optionData.sale_price;
      vueInstance.variantId= optionData.id
    },
    
    setActiveGrain(optionData) {
      let vueInstance = this;
      vueInstance.grainId= optionData.id
    },

    setThumbsSwiper(swiper){
      this.thumbsSwiper = swiper;
    },
    ...mapActions("customer", {
        actionProductDetails: "productDetails",
    }),
    ...mapActions("customer", {
        actionAddToProductCart: "addToProductCart",
    }),
    ...mapActions("customer", {
        actionAddToWish: "addToWish",
    }),
  },
};
</script>

