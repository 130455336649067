import { createApp } from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import router from './router'
import store from './store'
import Vue3Toastify, {toast, ToastContainerOptions } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';


const app = createApp(App).use(store).use(router).use(VueRouter)
app.use(
    Vue3Toastify,  
    {
        autoClose: 2000,
        multiple: false,
        theme:"colored",
        transition:toast.TRANSITIONS.Bounce,
        style: {
            opacity: '1',
            userSelect: 'initial',
        },
    },
); 
// import $ from "jquery";
// import jQuery from 'jquery'
// window.jQuery = jQuery
// app.prototype.jQuery = jQuery
window.$ = window.jQuery = require('jquery');

import '../src/assets/js/bootstrap/bootstrap.bundle.min.js'
import '../src/assets/js/jquery-3.6.0.min.js'
import '../src/assets/css/vendors/bootstrap.css'; 
import '../src/assets/css/vendors/font-awesome.css'; 
import '../src/assets/css/style.css'; 


import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css"; 


app.component("infinite-loading", InfiniteLoading, {
    props: {
      spinner: 'waveDots',
    },
    slots: {
      noMore: 'No more data available',
      noResults: 'No data available'
    },
});

app.config.globalProperties.$filters = {
    longDate(dateFormate) {
        if  (!dateFormate)  {
            return  "";
        }
        var date = new Date (dateFormate.replace(/-/g, "/"));
        return  date.toLocaleString ('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'});
    },
    longDateCreatedAt(dateFormate) {
        if  (!dateFormate)  {
            return  "";
        }
        var date = new Date (dateFormate);
        return  date.toLocaleString ('en-GB', {year: 'numeric', month: '2-digit', day: '2-digit',  hour12: true});
        // return  date.toLocaleString ('en-GB', { hour12: true });
    },
    longDateSec(dateFormate) {
        if  (!dateFormate)  {
            return  "";
        }        
        var date = new Date (dateFormate*1000);
        return  date.toLocaleString ('en-GB', {year: 'numeric', month: 'long', day: 'numeric'});
    },
    longDateSecCreatedAt(dateFormate) {
        if  (!dateFormate)  {
            return  "";
        }
        var date = new Date (dateFormate);
        return  date.toLocaleString ('en-US', {year: 'numeric', month: 'long', day: 'numeric'});
    },
    priceFormate(price) {
        return  '$'+price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    },
    afterFromTodayDays(dateFormate) {
        if  (!dateFormate)  {
            return  "";
        }
        var date = new Date(dateFormate.replace(/-/g, "/"));
        var future = new Date(date.setDate(date.getDate() + 30));
        return  future.toLocaleString ('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'});
    },

}

app.mount('#app')
