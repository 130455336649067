<template>  
    <div class="reviewer-box">
      <i class="fa-solid fa-quote-right"></i>
      <div class="product-rating">
          <star-rating :max="5" :current="customerReview.rate" />
      </div>

      <h3>{{ customerReview.title }}</h3> 

      <p>{{ customerReview.comment }}</p>

      <div class="reviewer-profile">
        <!-- <div class="reviewer-image">
          <img :src="customerReview.photo" class="blur-up lazyload" alt="" />
        </div> -->

        <div class="reviewer-name">
          <h4>{{ customerReview.first_name }} {{ customerReview.last_name }}</h4>
          <h6>verified customer</h6>
        </div>
      </div>
    </div>   
</template>


<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, EffectFade, FreeMode, Pagination } from 'swiper';
import 'swiper/css'
import "swiper/css/effect-fade";
import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import starRating from "../RatingComponent";
export default {
    components: { Swiper, SwiperSlide, starRating},
    props: ['customerReview'],
    data() {
        return {
            baseUrl: process.env.BASE_URL,
            modules: [Autoplay, EffectFade, FreeMode, Pagination],
        };
    },
   
};
</script>
<style scoped>
.product-rating span {
    color: #ffb321 !important;
    font-size: 12px;
    margin-left: 5px;
}
</style>
