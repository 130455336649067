<template>
   <div class="star-rating">
    <span v-for="n in max" :key="n">&star;</span>
    <div class="star-rating__current" :style="{width: getRating + '%'}">
      <span v-for="n in max" :key="n">&starf;</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['max', 'current'],
  computed: {
    getRating: function() {
      return (this.current / this.max) * 100
    }
  }
}
</script>

<style scoped>
.star-rating {
  color: #ffb321 !important;
  display: inline-block;
  font-size: 1.75em;
  position: relative;
  transform: translate(-6px);
}
.star-rating__max, .star-rating__current {
  position: absolute;
  top: 0;
}
.star-rating__current {
  overflow: hidden;
  white-space: nowrap;
}

span {
  display: inline;
}
.product-rating span {
    color: #ffb321 !important;
    font-size: 20px;
    margin-left: 5px;
}
</style>